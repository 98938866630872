import agent from "api/agent";
import { EnsureOk } from "api/utils";
import { CatchError } from "lib/misc/CatchError";
import { PromotionalGame } from "lib/models";
import { makeAutoObservable, runInAction } from "mobx";

export class PromotionalStore {
  games: PromotionalGame[] = [];
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  create(data: {
    title: string,
    genre: string,
    image?: File,
    gameUrl?: string;
  }) {
    return CatchError(async () => {
      let imageUrl = undefined;

      if (data.image) {
        const uploadResponse =
          await agent.cloudStorage.uploadFileWithPresignedUrl(
            `images/${crypto.randomUUID()}`,
            data.image,
          );

        EnsureOk(uploadResponse);

        imageUrl = uploadResponse.data!.url;
      }

      if (!imageUrl) {
        throw new Error("Unknown error when upload image");
      }

      const response = await agent.promotional.create({
        ...data,
        imageUrl: imageUrl,
      });

      EnsureOk(response);
    }, this.setLoading.bind(this));
  }

  delete(id: string) {
    return CatchError(async () => {
      const response = await agent.promotional.delete(id);

      EnsureOk(response);
    }, this.setLoading.bind(this))
  }

  update(id: string, data: {
    title?: string,
    genre?: string,
    image?: File,
    gameUrl?: string;
  }) {
    return CatchError(async () => {
      let imageUrl = undefined;

      if (data.image) {
        const uploadResponse =
          await agent.cloudStorage.uploadFileWithPresignedUrl(
            `images/${crypto.randomUUID()}`,
            data.image,
          );

        EnsureOk(uploadResponse);

        imageUrl = uploadResponse.data!.url;
      }

      const response = await agent.promotional.update(id, {
        ...data,
        imageUrl: imageUrl,
      });

      EnsureOk(response);
    }, this.setLoading.bind(this))
  }

  loadAll() {
    return CatchError(async () => {
      const response = await agent.promotional.getAll();
      EnsureOk(response);

      runInAction(() => {
        this.games = response.data?.data ?? [];
      });
    }, this.setLoading.bind(this))
  }
}
