import { Flex, Image, Spin, Tabs } from "antd";
import { NavbarWrapper } from "app/components/GlobalNavbar";
import { App } from "lib/models";
import router from "lib/router";
import { RouteGuard } from "lib/router/RouteGuard";
import {
  useBreadcrumb,
  useGlobalStore
} from "lib/storage/GlobalStore";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { BundlesApplicationSection } from "./components/BundlesApplicationSection";
import { DetailsApplicationSection } from "./components/DetailsApplicationSection";
import { SettingsApplicationSection } from "./components/SettingsApplicationSection";

const ApplicationPageTitle = (props: { application: App }) => {
  return (
    <>
      <Flex align="center">
        <Image
          src={props.application.imageUrl}
          height={32}
          width={32}
          preview={false}
        />

        <div className="px-2" />

        <div>{props.application.name}</div>
      </Flex>
    </>
  );
};

interface Props {
}

export const ApplicationPage = observer((props: Props) => {
  const { appStore } = useGlobalStore();
  const { id, tab = "" } = useParams();


  useBreadcrumb(
    appStore.application ? (
      <ApplicationPageTitle application={appStore.application} />
    ) : (
      "Application"
    ),
    [
      {
        title: "Applications",
        path: "/applications",
      },
      {
        title: appStore.application?.name ?? <Spin />,
        path: `/applications/${id}/${tab}`,
      },
    ],
  );

  useEffect(() => {
    if (!id) return;
    appStore.load(id).finally();
  }, [id, appStore]);

  if (!id) {
    return <Navigate to={"/applications"} />;
  }

  return (
    <>
      <RouteGuard>
        <NavbarWrapper selection="applications">
          <Tabs
            type="line"
            size="small"
            defaultActiveKey={tab}
            onChange={key => router.navigate(`/applications/${id}/${key}`)}
            items={[
              {
                key: "",
                label: "General",
                children: <DetailsApplicationSection />,
              },
              {
                key: "builds",
                label: "Builds",
                children: <BundlesApplicationSection />,
              },
              {
                key: "settings",
                label: "Settings",
                children: <SettingsApplicationSection />,
              },
            ]}
          />
        </NavbarWrapper>
      </RouteGuard>
    </>
  );
});
