import React from 'react';
import { Select } from 'antd';

interface Props {
    onChange: (value: string) => void;
}

export const PlatformSelect: React.FC<Props> = ({ onChange }) => {
    return (
        <Select style={{ width: 200, marginRight: 16 }} placeholder="Select Platform" onChange={onChange}>
            <Select.Option value="android">Android</Select.Option>
            <Select.Option value="ios">iOS</Select.Option>
        </Select>
    );
};