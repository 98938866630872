import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Flex, Table, Typography } from "antd";
import { Popconfirm } from "antd/lib";
import { CreateAndroidKeystoreDrawer } from "app/components/drawers/CreateAndroidKeystoreDrawer";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

interface Props { }

export const AdminSettingsAndroidSection = observer((props: Props) => {
  const { ksStore } = useGlobalStore();

  useEffect(() => {
    ksStore.loadAll().finally();
  }, [ksStore]);

  return (
    <>
      <Typography.Title level={3}>Keystores</Typography.Title>

      <Typography.Text>
        You can manage keystores used in this system here.
      </Typography.Text>

      <Flex justify="flex-end">
        <CreateAndroidKeystoreDrawer
          render={(open, close) => (
            <Button size="large" onClick={open} type="primary" icon={<PlusCircleFilled />}>
              CREATE
            </Button>
          )}
        />
      </Flex>

      <div className="my-4" />

      <Table
        columns={[
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Action",
            render: (value) =>
              value.id && (
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() =>
                    ksStore
                      .delete(value.id)
                      .then(() => ksStore.loadAll())
                  }
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              ),
            width: 100,
            align: "center",
          },
        ]}
        dataSource={ksStore.keystores}
        bordered
        size="large"
        loading={ksStore.isLoading}
      />
    </>
  );
});
