import axios from "axios";
import { ExpiredActionType, PagingRequest, PagingResponse, RuleType, UserAccessRule } from "lib/models";
import { AxiosWrapper, BaseAPIMainConfig } from "./utils";

export const uar = {
    getAll: (params?: PagingRequest) => AxiosWrapper(axios.get<PagingResponse<UserAccessRule>>("/user-access-rule", {
        ...BaseAPIMainConfig,
        params: params
    })),

    delete: (id: string) =>
        AxiosWrapper(axios.delete(`/user-access-rule/${id}`, BaseAPIMainConfig)),

    update: (
        id: string,
        data: {
            pattern?: string;
            expiredAt?: string;
            expiredAction?: ExpiredActionType;
            type?: RuleType;
        },
    ) => AxiosWrapper(axios.put(`/user-access-rule/${id}`, data, BaseAPIMainConfig)),

    create: (data: {
        pattern: string;
        expiredAt?: string;
        expiredAction?: ExpiredActionType;
        type?: RuleType;
    },
    ) => AxiosWrapper(axios.post("/user-access-rule", data, BaseAPIMainConfig)),
};
