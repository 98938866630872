import agent from "api/agent";
import { EnsureOk } from "api/utils";
import { CatchError } from "lib/misc/CatchError";
import { User } from "lib/models";
import { makeAutoObservable, runInAction } from "mobx";

export class UserStore {
  users: User[] = [];
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  create(data: {
    email: string,
    name: string,
    password: string,
    image?: File,
    orgId?: string,
    roleId?: string,
  }) {
    return CatchError(async () => {
      let avatarUrl = undefined;

      if (data.image) {
        const uploadResponse =
          await agent.cloudStorage.uploadFileWithPresignedUrl(
            `images/${crypto.randomUUID()}`,
            data.image,
          );

        EnsureOk(uploadResponse);

        avatarUrl = uploadResponse.data!.url;
      }

      const response = await agent.user.create({
        ...data,
        avatar: avatarUrl,
      });

      EnsureOk(response);
    }, this.setLoading.bind(this));
  }

  delete(id: string) {
    return CatchError(async () => {
      const response = await agent.user.delete(id);

      EnsureOk(response);
    }, this.setLoading.bind(this))
  }

  update(id: string, data: { orgId?: string, roleId?: string }) {
    return CatchError(async () => {
      const response = await agent.user.updateUserInfo(id, data);

      EnsureOk(response);
    }, this.setLoading.bind(this))
  }

  loadAll() {
    return CatchError(async () => {
      const response = await agent.user.getAll();
      EnsureOk(response);


      runInAction(() => {
        this.users = response.data?.data ?? [];
      });
    }, this.setLoading.bind(this))
  }
}
