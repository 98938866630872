import axios from "axios";
import { App, BuildBundle, PagingRequest, PagingResponse, PlatformType } from "lib/models";
import { AxiosWrapper, BaseAPIMainConfig } from "./utils";

export const application = {
  getAll: (params?: PagingRequest) =>
    AxiosWrapper(
      axios.get<PagingResponse<App>>(
        "/application", {
        ...BaseAPIMainConfig,
        params: params
      },)
    ),

  get: (id: string) =>
    AxiosWrapper(axios.get<App>(`/application/${id}`, BaseAPIMainConfig)),

  create: (data: {
    name: string;
    imageUrl: string;
    platformType: PlatformType;
  }) => AxiosWrapper(axios.post("/application", data, BaseAPIMainConfig)),

  update: (
    id: string,
    data: {
      name?: string;
      imageUrl?: string;
      properties?: { [key: string]: string };
    },
  ) => AxiosWrapper(axios.put(`/application/${id}`, data, BaseAPIMainConfig)),

  delete: (id: string) =>
    AxiosWrapper(axios.delete(`/application/${id}`, BaseAPIMainConfig)),

  getBuilds: (id: string, params?: PagingRequest) =>
    AxiosWrapper(
      axios.get<PagingResponse<BuildBundle>>(
        `/application/${id}/builds`,
        {
          ...BaseAPIMainConfig,
          params: params
        },
      ),
    ),
};
