import { Button, Typography } from "antd";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { Observer, observer } from "mobx-react-lite";
import { useState } from "react";
import { FileSelector } from "../FileSelector";
import { BaseDrawer, IDrawerProps } from "./BaseDrawer";

interface Props extends IDrawerProps { }

export const UploadBuildBundleDrawer = observer((props: Props) => {
  class Store {
    file?: File;

    constructor() {
      makeAutoObservable(this);
    }

    setFile(file?: File) {
      this.file = file;
    }
  }

  const { buildStore, appStore } = useGlobalStore();
  const [store] = useState(() => new Store());

  return (
    <>
      <BaseDrawer
        render={props.render}
        onOpenChanged={() => {
          store.setFile();
        }}
      >
        {(open, close) => (
          <Observer>
            {() => {
              const appId = appStore.application?.id;

              if (!appId) return <></>;

              return (
                <>
                  <div className="mb-4">
                    <Typography.Title level={5}>
                      Build bundle file
                    </Typography.Title>

                    <FileSelector
                      label="Upload Build Bundle"
                      file={store.file}
                      onChange={(file) => store.setFile(file)}
                      accept=".aab"
                      isError={!store.file}
                    />
                  </div>

                  <div className="mb-4">
                    <Button
                      onClick={() =>
                        store.file &&
                        buildStore
                          .upload({
                            appId: appId,
                            file: store.file,
                          })
                          .success(() =>
                            buildStore.paging
                              .init({ appId })
                              .finally(close),
                          )
                      }
                      loading={buildStore.loading}
                    >
                      Create
                    </Button>
                  </div>
                </>
              );
            }}
          </Observer>
        )}
      </BaseDrawer>
    </>
  );
});
