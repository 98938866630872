import { Button, Divider, Flex, Input, Typography, Upload } from "antd";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props { }

export const AccountSettingsSection = observer((props: Props) => {
  class Store {
    name: string = "";

    previewAvatar: string = "";
    avatar?: File;

    newPassword: string = "";
    confirmPassword: string = "";


    constructor() {
      makeAutoObservable(this);
    }

    setAvatar(avatar?: File) {
      this.avatar = avatar;

      if (avatar) {
        avatar.arrayBuffer().then((buffer) => {
          const blob = new Blob([buffer]);
          const url = URL.createObjectURL(blob);
          this.setPreviewAvatar(url);
        });
      } else {
        this.setPreviewAvatar("");
      }
    }

    setPreviewAvatar(avatar: string) {
      this.previewAvatar = avatar;
    }

    setName(name: string) {
      this.name = name;
    }

    setNewPassword(password: string) {
      this.newPassword = password;
    }

    setConfirmPassword(password: string) {
      this.confirmPassword = password;
    }

    get isProfileValid() {
      return !store.avatar && store.name.trim() === globalStore.user?.name.trim();
    }

    get isPasswordValid() {
      return store.newPassword.trim() === "" ||
        store.confirmPassword.trim() === "" ||
        store.confirmPassword.trim() !== store.newPassword.trim();
    }
  }

  const [store] = useState(() => new Store());
  const navigate = useNavigate();
  const globalStore = useGlobalStore();

  useEffect(() => {
    store.setAvatar();
    store.setPreviewAvatar(globalStore.user?.avatarUrl ?? "");
    store.setName(globalStore.user?.name ?? "");
    store.setNewPassword("");
    store.setConfirmPassword("");
  }, [globalStore.user?.avatarUrl, globalStore.user?.name, store]);

  const clearPassword = useCallback(() => {
    store.setNewPassword("");
    store.setConfirmPassword("");
  }, [store])

  return (
    <>
      <Flex>
        <div className="p-6 flex-[1]">
          <Upload
            customRequest={(_) => _}
            maxCount={1}
            showUploadList={false}
            onChange={async (info) => {
              store.setAvatar(info.file.originFileObj);
            }}
          >
            <img
              src={
                store.previewAvatar ||
                "/user-default-avatar.png"
              }
              alt=""
              className="cursor-pointer rounded-full aspect-square object-cover border-dashed border-2"
            />
          </Upload>
        </div>

        <div className="p-6 flex-[3]">
          <div className="p-2">
            <Typography.Title level={5}>Email</Typography.Title>

            <Input value={globalStore.user?.email} readOnly />
          </div>

          <div className="p-2">
            <Typography.Title level={5}>Full Name</Typography.Title>

            <Input
              value={store.name}
              onChange={(e) => store.setName(e.target.value)}
            />
          </div>

          <Divider />

          <div className="p-2">
            <Typography.Title level={5}>New Password</Typography.Title>

            <Input
              value={store.newPassword}
              onChange={(e) => store.setNewPassword(e.target.value)}
              placeholder="Enter new password here"
              type="password"
            />
          </div>

          <div className="p-2">
            <Typography.Title level={5}>Confirm Password</Typography.Title>

            <Input
              value={store.confirmPassword}
              onChange={(e) => store.setConfirmPassword(e.target.value)}
              placeholder="Re-Enter new password here"
              type="password"
            />
          </div>

          <Divider />

          <div className="p-2">
            <Flex className="space-x-2">
              <Button
                onClick={() =>
                  globalStore.updateCurrentUser({
                    avatar: store.avatar,
                    name: store.name,
                  })
                }
                type="primary"
                disabled={store.isProfileValid}
                loading={globalStore.isLoading}
              >
                Update Profile
              </Button>

              <Button
                onClick={() =>
                  globalStore.updateCurrentUser({
                    password: store.newPassword
                  }).then(clearPassword)
                }
                type="primary"
                disabled={store.isPasswordValid}
                loading={globalStore.isLoading}
              >
                Update Password
              </Button>

              <Button
                onClick={() => {
                  localStorage.removeItem("jwt");
                  navigate("/login");
                }}
              >
                Logout
              </Button>
            </Flex>
          </div>
        </div>
      </Flex>
    </>
  );
});
