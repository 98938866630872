import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Flex, Input, Select, Table } from "antd";
import { Popconfirm } from "antd/lib";
import { Enum } from "lib/misc/common.extension";
import { PermissionType, Role } from "lib/models";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";

interface Props { }

export const AdminSettingsRolesSection = observer((props: Props) => {
  const { roleStore } = useGlobalStore();

  useEffect(() => {
    roleStore.loadAll().finally();
  }, [roleStore]);

  const setRoleName = (id: string, name: string, roles: Role[]) => {
    const role = roles.find((value) => value.id === id);

    if (!role) return;

    role.name = name;
  };

  const reloadRoles = useCallback(() => { roleStore.loadAll().finally() }, [roleStore]);
  const isLoading = roleStore.isLoading;

  return (
    <>
      <Flex justify="flex-end">
        <Button
          size="large"
          onClick={() => roleStore.create({
            name: "",
            permissions: [],
          }).then(reloadRoles)}
          type="primary"
          icon={<PlusCircleFilled />}
        >
          CREATE
        </Button>
      </Flex>

      <div className="my-4" />

      <Table
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            render: (value, record) => (
              <Input
                value={value}
                status={value.trim() ? undefined : "error"}
                onChange={(e) => setRoleName(record.id, e.target.value, roleStore.roles)}
                onBlur={() =>
                  roleStore
                    .update(record.id, {
                      name: value,
                    })
                    .then(reloadRoles)
                }
                count={{
                  max: 64,
                  show: true
                }}
                disabled={isLoading}
              />
            ),
          },
          {
            title: "Permissions",
            dataIndex: "permissions",
            render: (value: Array<PermissionType>, record) => (
              <Select
                mode="multiple"
                options={
                  Enum.entries(PermissionType)
                    .map(([key, val]) => ({
                      label: key,
                      value: val,
                    }))}
                className="w-full"
                value={value}
                onChange={(value) =>
                  roleStore
                    .update(record.id, {
                      permissions: value
                    })
                    .then(reloadRoles)
                }
                disabled={isLoading}
              />
            ),
          },
          {
            title: "Action",
            render: (value) =>
              value.id && (
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() =>
                    roleStore.delete(value.id).then(reloadRoles)
                  }
                >
                  <Button
                    icon={<DeleteOutlined />}
                    disabled={isLoading}
                  />
                </Popconfirm>
              ),
            width: 64,
            align: "center",
          },
        ]}
        dataSource={roleStore.roles.map((value) => ({ ...value }))}
        bordered
        size="large"
        loading={isLoading}
      />
    </>
  );
});
