import React from 'react';
import { DatePicker } from 'antd';
import type { Dayjs } from 'dayjs';

const { RangePicker } = DatePicker;

interface Props {
    onChange: (dates: [Dayjs | null, Dayjs | null] | null) => void;
}

export const DateRangePicker: React.FC<Props> = ({ onChange }) => {
    return (
        <RangePicker style={{ marginRight: 16 }} onChange={onChange} />
    );
};
