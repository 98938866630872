import axios, { HttpStatusCode } from "axios";
import { androidKeystore } from "./android-keystore";
import { application } from "./application";
import { auth } from "./auth";
import { build } from "./build";
import { cloudStorage } from "./cloud-storage";
import { misc } from "./misc";
import { organization } from "./organization";
import { promotional } from "./promotional-game";
import { role } from "./role";
import { user } from "./user";
import { uar } from "./user-access-rule";

const AuthorizationSupportedUrls = [process.env.REACT_APP_API_BASE_URL];

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("jwt");

  if (
    token &&
    config.baseURL &&
    config.headers &&
    !config.headers.Authorization &&
    AuthorizationSupportedUrls.includes(config.baseURL)
  ) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Cache-Control"] = "no-cache";
  }

  return config;
});

axios.interceptors.response.use((config) => {

  if (config.status === HttpStatusCode.Unauthorized) {
    localStorage.removeItem("jwt");
  }

  return config;
})

const agent = {
  auth,
  user,
  application,
  cloudStorage,
  organization,
  role,
  androidKeystore,
  misc,
  build,
  uar,
  promotional,
};

export default agent;
