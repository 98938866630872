import { Button, Input, Typography } from "antd";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { Observer, observer } from "mobx-react-lite";
import { useState } from "react";
import { FileSelector } from "../FileSelector";
import { BaseDrawer, IDrawerProps } from "./BaseDrawer";


interface Props extends IDrawerProps { }

export const CreateOrganizationDrawer = observer((props: Props) => {
  class Store {
    isOrganizationCreating: boolean = false;
    name: string = "";
    image?: File;

    get valid() {
      return this.name.trim() && this.image;
    }

    constructor() {
      makeAutoObservable(this);
    }

    setImage(image?: File) {
      this.image = image;
    }

    setName(name: string) {
      this.name = name;
    }
  }

  const { orgStore } = useGlobalStore();
  const [store] = useState(() => new Store());

  return (
    <>
      <BaseDrawer
        render={props.render}
        onOpenChanged={(open) => {
          store.setName("");
          store.setImage();
        }}
      >
        {(open, close) => (
          <Observer>
            {() => (
              <>
                <div className="mb-4">
                  <Typography.Title level={5}>Name</Typography.Title>

                  <Input
                    size="large"
                    value={store.name}
                    status={store.name.trim() ? undefined : "error"}
                    onChange={(e) => store.setName(e.target.value)}
                    count={{
                      max: 64,
                      show: true
                    }}
                  />
                </div>

                <div className="mb-4">
                  <Typography.Title level={5}>Image</Typography.Title>

                  <FileSelector
                    label="Upload Org Image"
                    file={store.image}
                    accept="image/*"
                    onChange={(file) => store.setImage(file)}
                    isError={!store.image}
                  />
                </div>

                <div className="mb-4">
                  <Button
                    onClick={() => store.image && orgStore.create({
                      image: store.image,
                      name: store.name,
                    }).then(close).then(() => orgStore.loadAll())}
                    loading={store.isOrganizationCreating}
                    disabled={!store.valid}
                  >
                    Create
                  </Button>
                </div>
              </>
            )}
          </Observer>
        )}
      </BaseDrawer>
    </>
  );
});
