import { Button, Divider, Flex, Input, Typography } from "antd";
import { FileSelector } from "app/components/FileSelector";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";

interface Props { }

export const SettingsApplicationSection = observer((props: Props) => {
  class Store {
    image?: File;
    name: string = "";

    constructor() {
      makeAutoObservable(this);
    }

    setImage(image?: File) {
      this.image = image;
    }

    setName(name: string) {
      this.name = name;
    }
  }

  const [store] = useState(() => new Store());
  const { appStore } = useGlobalStore();

  useEffect(() => {
    if (!appStore.application) return;

    store.setName(appStore.application.name);
  }, [store, appStore.application]);

  const refresh = useCallback(() => {
    if (appStore.application) appStore.load(appStore.application.id).finally();
  }, [appStore]);

  return (
    <>
      <div className="rounded-xl border border-dashed shadow-xl p-6">
        <Typography.Title level={2}>Application Settings</Typography.Title>

        <Divider />

        <Typography.Title level={5}>Name</Typography.Title>

        <Input
          size="large"
          value={store.name}
          onChange={(e) => store.setName(e.target.value)}
        />

        <div className="p-3" />

        <Typography.Title level={5}>Image</Typography.Title>

        <FileSelector
          label="Upload App Image"
          file={store.image}
          onChange={(file) => store.setImage(file)}
          accept="image/*"
        />

        <div className="p-3" />

        <Flex justify="flex-end" align="center">
          <Button
            size="large"
            type="primary"
            onClick={() =>
              appStore.application &&
              appStore
                .update(appStore.application.id, {
                  name: store.name,
                  image: store.image,
                })
                .then(() => {
                  store.setName("");
                  store.setImage();
                  refresh();
                })
            }
            loading={appStore.isLoading}
          >
            Update
          </Button>
        </Flex>
      </div>
    </>
  );
});
