import { Button, DatePicker, Input, Select, Typography } from "antd";
import { Enum } from "lib/misc/common.extension";
import { ExpiredActionType, RuleType } from "lib/models";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { Observer, observer } from "mobx-react-lite";
import { useState } from "react";
import { BaseDrawer, IDrawerProps } from "./BaseDrawer";

interface Props extends IDrawerProps {
}

export const CreateUserAccessRuleDrawer = observer((props: Props) => {
  class Store {

    open: boolean = false;
    pattern: string = "";
    expiredAt?: string;
    expiredAction?: ExpiredActionType;
    type?: RuleType;

    constructor() {
      makeAutoObservable(this);
    }

    setPattern(value: string) {
      this.pattern = value;
    }

    setExpiredAt(value?: string) {
      this.expiredAt = value;
    }

    setExpiredAction(value?: ExpiredActionType) {
      this.expiredAction = value;
    }

    setType(value?: RuleType) {
      this.type = value;
    }

    reset() {
      this.setPattern("");
      this.setExpiredAt();
      this.setExpiredAction(ExpiredActionType.MoveToDisabled);
      this.setType(RuleType.Allow);
    }

    get valid() {
      return this.pattern.trim();
    }
  }

  const [store] = useState(() => new Store());
  const { uarStore } = useGlobalStore();

  return (
    <>
      <BaseDrawer
        render={props.render}
        onOpenChanged={(open) => {
          store.reset();
        }}>
        {
          (open, close) => (
            <Observer>
              {
                () => (
                  <>
                    <div className="mb-4">
                      <Typography.Title level={5}>Pattern (regex)</Typography.Title>

                      <Input
                        size="large"
                        value={store.pattern}
                        onChange={(e) => store.setPattern(e.target.value)}
                        placeholder=".*?@the1studio.org"
                        status={store.pattern.trim() ? undefined : "error"}
                        count={{
                          max: 256,
                          show: true,
                        }}
                      />
                    </div>


                    <div className="mb-4">
                      <Typography.Title level={5}>Expired Date</Typography.Title>

                      <DatePicker
                        style={{
                          width: "100%"
                        }}
                        size="large"
                        showTime={{
                          format: 'HH:mm',
                          use12Hours: true,
                          showNow: true,
                        }}
                        format={"YYYY-MM-DD HH:mm A"}
                        onChange={(date) => store.setExpiredAt(date?.toISOString())}
                      />
                    </div>

                    <div className="mb-4">
                      <Typography.Title level={5}>Expired Action</Typography.Title>

                      <Select
                        options={
                          Enum.entries(ExpiredActionType)
                            .map(([key, value]) => ({
                              label: key,
                              value: value,
                            }))
                        }
                        value={store.expiredAction}
                        className="w-full"
                        size="large"
                        onSelect={(expiredAction) => store.setExpiredAction(expiredAction)}
                      />
                    </div>

                    <div className="mb-4">
                      <Typography.Title level={5}>Type</Typography.Title>

                      <Select
                        options={
                          Enum.entries(RuleType)
                            .map(([key, value]) => ({
                              label: key,
                              value: value,
                            }))
                        }
                        value={store.type}
                        className="w-full"
                        size="large"
                        onSelect={(type) => store.setType(type)}
                      />
                    </div>

                    <div className="mb-4">
                      <Button
                        onClick={() => uarStore.create({
                          pattern: store.pattern,
                          expiredAction: store.expiredAction,
                          expiredAt: store.expiredAt,
                          type: store.type,
                        }).then(() => uarStore.loadAll())}
                        loading={uarStore.isLoading}
                        disabled={!store.valid}
                      >
                        Create
                      </Button>
                    </div>
                  </>
                )
              }
            </Observer>
          )
        }
      </BaseDrawer>
    </>
  );
});
