import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { EnsureOk } from "../../api/utils";
import { CatchError } from "../misc/CatchError";
import { Organization } from "../models";

export class OrganizationStore {
  organizations: Organization[] = [];
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  async loadAll() {
    await CatchError(async () => {
      this.setLoading(true);

      const response = await agent.organization.getAll();

      EnsureOk(response);

      runInAction(() => {
        this.organizations = response.data?.data ?? [];
      });
    }, this.setLoading.bind(this));
  }

  async delete(id: string) {
    await CatchError(async () => {
      this.setLoading(true);

      const response = await agent.organization.delete(id);

      EnsureOk(response);
    }, this.setLoading.bind(this));
  }

  async create(data: { image: File, name: string }) {
    await CatchError(async () => {
      this.isLoading = true;

      if (!data.image) {
        throw new Error("Image not found");
      }

      const uploadResponse =
        await agent.cloudStorage.uploadFileWithPresignedUrl(
          `images/${crypto.randomUUID()}`,
          data.image,
        );

      EnsureOk(uploadResponse);

      const response = await agent.organization.create({
        name: data.name,
        image: uploadResponse.data!.url,
      });

      EnsureOk(response);
    }, this.setLoading.bind(this));
  }
}
