import { NavbarWrapper } from "app/components/GlobalNavbar";
import { RouteGuard } from "lib/router/RouteGuard";
import { useBreadcrumb } from "lib/storage/GlobalStore";
import { observer } from "mobx-react-lite";
import { Layout, Button, Card } from 'antd';
import React, { useState } from 'react';
import type { Dayjs } from 'dayjs';
import { GameSelect } from "./components/GameSelect";
import { PlatformSelect } from "./components/PlatformSelect";
import { DateRangePicker } from "./components/DateRangePicker";
import { Chart } from "./components/Chart";
import { DataTable } from "./components/DataTable";

interface Props { }

const { Header, Content } = Layout;

export const AnalyticsPage = observer((props: Props) => {
  useBreadcrumb("Analytics", [
    {
      title: "Analytics",
      path: "/analytics",
    },
  ]);

  const [_selectedGame, setSelectedGame] = useState<string | undefined>(undefined);
  const [_selectedPlatform, setSelectedPlatform] = useState<string | undefined>(undefined);
  const [_dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null] | null>(null);

  const chartData = [
    { date: 'Sep 6 2024', Christiano: 1.2, Messi: 0.8, LamineYamal: 0.9 },
    { date: 'Sep 7 2024', Christiano: 1.1, Messi: 0.9, LamineYamal: 0.7 },
    { date: 'Sep 8 2024', Christiano: 1.3, Messi: 1.1, LamineYamal: 0.8 },
    { date: 'Sep 9 2024', Christiano: 1.0, Messi: 1.2, LamineYamal: 1.0 },
    { date: 'Sep 10 2024', Christiano: 1.4, Messi: 0.7, LamineYamal: 0.9 },
  ];

  const tableData = [
    { key: 1, date: '2024-09-06', installDay: '100% 99', day1: '18.1816% 18', day2: '10.101% 10', day3: '7.0707% 7', day4: '5.0505% 5', day5: '3.0303% 3', day6: '0% 0', day7: '0% 0' },
    { key: 2, date: '2024-09-07', installDay: '100% 92', day1: '11.9565% 11', day2: '5.4348% 5', day3: '2.1739% 2', day4: '4.3478% 4', day5: '0% 0', day6: '0% 0', day7: '0% 0' },
    { key: 3, date: '2024-09-08', installDay: '100% 69', day1: '11.5942% 8', day2: '2.8986% 2', day3: '4.3478% 3', day4: '0% 0', day5: '0% 0', day6: '0% 0', day7: '0% 0' },
    { key: 4, date: '2024-09-09', installDay: '100% 47', day1: '10.6383% 5', day2: '10.6383% 5', day3: '0% 0', day4: '0% 0', day5: '0% 0', day6: '0% 0', day7: '0% 0' },
    { key: 5, date: '2024-09-10', installDay: '100% 469', day1: '21.322% 100', day2: '0% 0', day3: '0% 0', day4: '0% 0', day5: '0% 0', day6: '0% 0', day7: '0% 0' },
  ];

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Install Day',
      dataIndex: 'installDay',
      key: 'installDay',
      sorter: (a: any, b: any) => {
        const installA = parseInt(a.installDay.split(' ')[1], 10) || 0;
        const installB = parseInt(b.installDay.split(' ')[1], 10) || 0;
        return installA - installB;
      },
    },
    {
      title: 'Retention',
      children: [
        {
          title: 'Day 1',
          dataIndex: 'day1',
          key: 'day1',
          sorter: (a: any, b: any) => {
            const dayA = parseFloat(a.day1.split('%')[0]) || 0;
            const dayB = parseFloat(b.day1.split('%')[0]) || 0;
            return dayA - dayB;
          },
        },
        {
          title: 'Day 2',
          dataIndex: 'day2',
          key: 'day2',
          sorter: (a: any, b: any) => {
            const dayA = parseFloat(a.day2.split('%')[0]) || 0;
            const dayB = parseFloat(b.day2.split('%')[0]) || 0;
            return dayA - dayB;
          },
        },
        {
          title: 'Day 3',
          dataIndex: 'day3',
          key: 'day3',
          sorter: (a: any, b: any) => {
            const dayA = parseFloat(a.day3.split('%')[0]) || 0;
            const dayB = parseFloat(b.day3.split('%')[0]) || 0;
            return dayA - dayB;
          },
        },
        {
          title: 'Day 4',
          dataIndex: 'day4',
          key: 'day4',
          sorter: (a: any, b: any) => {
            const dayA = parseFloat(a.day4.split('%')[0]) || 0;
            const dayB = parseFloat(b.day4.split('%')[0]) || 0;
            return dayA - dayB;
          },
        },
        {
          title: 'Day 5',
          dataIndex: 'day5',
          key: 'day5',
          sorter: (a: any, b: any) => {
            const dayA = parseFloat(a.day5.split('%')[0]) || 0;
            const dayB = parseFloat(b.day5.split('%')[0]) || 0;
            return dayA - dayB;
          },
        },
        {
          title: 'Day 6',
          dataIndex: 'day6',
          key: 'day6',
          sorter: (a: any, b: any) => {
            const dayA = parseFloat(a.day6.split('%')[0]) || 0;
            const dayB = parseFloat(b.day6.split('%')[0]) || 0;
            return dayA - dayB;
          },
        },
        {
          title: 'Day 7',
          dataIndex: 'day7',
          key: 'day7',
          sorter: (a: any, b: any) => {
            const dayA = parseFloat(a.day7.split('%')[0]) || 0;
            const dayB = parseFloat(b.day7.split('%')[0]) || 0;
            return dayA - dayB;
          },
        },
      ]
    }
  ];


  return (
    <>
      <RouteGuard>
        <NavbarWrapper selection="analytics">
          <Layout>
            <Header className="site-layout-sub-header-background" style={{ padding: 0 }} />
            <Content style={{ margin: '24px 16px 0' }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Card style={{ marginBottom: 16 }}>
                  <GameSelect onChange={setSelectedGame} />
                  <PlatformSelect onChange={setSelectedPlatform} />
                  <DateRangePicker onChange={setDateRange} />
                  <Button type="primary">Apply</Button>
                </Card>
                <Card>
                  <Chart data={chartData} />
                </Card>
                <Card style={{ marginTop: 16 }}>
                  <DataTable columns={columns} data={tableData} />
                </Card>
              </div>
            </Content>
          </Layout>
        </NavbarWrapper>
      </RouteGuard>
    </>
  );
});
