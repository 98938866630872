import axios from "axios";
import { AxiosWrapper, BaseAPIMainConfig } from "./utils";

type TokenResponse = {
  token: string;
};

export const auth = {
  loginCredentials: (data: { email: string; password: string }) =>
    AxiosWrapper(
      axios.post<TokenResponse>(
        "/auth/login/credentials",
        data,
        BaseAPIMainConfig,
      ),
    ),

  loginGoogle: (data: { jwt: string }) =>
    AxiosWrapper(
      axios.post<TokenResponse>("/auth/login/google", data, BaseAPIMainConfig),
    ),
};
