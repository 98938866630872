import { makeAutoObservable, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { createContext, useContext, useEffect, useState } from "react";
import agent from "../../api/agent";
import { EnsureOk } from "../../api/utils";
import { CatchError } from "../misc/CatchError";
import { User } from "../models";
import { AndroidKeystoreStore } from "./AndroidKeystoreStore";
import { AppStore } from "./AppStore";
import { AuthStore } from "./AuthStore";
import { BuildBundleStore } from "./BuildBundleStore";
import { OrganizationStore } from "./OrganizationStore";
import { PromotionalStore } from "./PromotionalStore";
import { RoleStore } from "./RoleStore";
import { UserAccessRuleStore } from "./RuleStore";
import { UserStore } from "./UserStore";

type BreadcrumbNode = {
  title: React.ReactNode | string;
  path: string;
};

export class GlobalStore {
  user?: User;
  supportedIntegrations: { key: string }[] = [];

  isLoading: boolean = false;

  breadcrumb: Array<BreadcrumbNode> = [];
  title: React.ReactNode | string = (<></>);

  readonly appStore: AppStore = new AppStore();
  readonly userStore: UserStore = new UserStore();
  readonly buildStore: BuildBundleStore = new BuildBundleStore();
  readonly ksStore: AndroidKeystoreStore = new AndroidKeystoreStore();
  readonly authStore: AuthStore = new AuthStore();
  readonly orgStore: OrganizationStore = new OrganizationStore();
  readonly roleStore: RoleStore = new RoleStore();
  readonly uarStore: UserAccessRuleStore = new UserAccessRuleStore();
  readonly promotionalStore: PromotionalStore = new PromotionalStore();

  constructor() {
    makeAutoObservable(this);
  }

  load() {
    return CatchError(async () => {
      await Promise.all([this.loadUser(), this.loadSupportedIntegrations()]);
    }, this.setLoading.bind(this));
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setBreadcrumb(data: Array<BreadcrumbNode>) {
    this.breadcrumb = data;
  }

  setTitle(title: React.ReactNode | string) {
    this.title = title;
  }

  private async loadUser() {
    const response = await agent.user.getCurrentUserInfo();

    EnsureOk(response);

    runInAction(() => {
      this.user = response.data;
    });
  }

  private async loadSupportedIntegrations() {
    const response = await agent.misc.getAllSupportedIntegrations();

    EnsureOk(response);

    runInAction(() => {
      this.supportedIntegrations = response.data ?? [];
    });
  }

  updateCurrentUser(data: { avatar?: File, name?: string, password?: string }) {
    return CatchError(async () => {
      let avatarUrl = undefined;

      if (data.avatar) {
        const uploadResponse =
          await agent.cloudStorage.uploadFileWithPresignedUrl(
            `images/${crypto.randomUUID()}`,
            data.avatar,
          );

        EnsureOk(uploadResponse);

        avatarUrl = uploadResponse.data!.url;
      }

      const response = await agent.user.setCurrentUserInfo({
        ...data,
        avatar: avatarUrl
      });

      EnsureOk(response);
    }, this.setLoading.bind(this)).success(() => CatchError(this.loadUser.bind(this)))
  }
}

const GlobalContext = createContext(new GlobalStore());

export const useGlobalStore = () => {
  return useContext(GlobalContext);
};

export const useBreadcrumb = (
  title: React.ReactNode | string,
  data: Array<BreadcrumbNode>,
) => {
  const globalStore = useGlobalStore();

  useEffect(() => {
    globalStore.setTitle(title);
    globalStore.setBreadcrumb(data);
  }, [globalStore, title, data]);
};

export const GlobalContextProvider = observer(({ children }: any) => {
  const [store] = useState(() => new GlobalStore());

  return (
    <GlobalContext.Provider value={store}>
      {children}
    </GlobalContext.Provider>
  );
});
