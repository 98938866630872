import { BarChartOutlined, DownloadOutlined, EllipsisOutlined, EyeOutlined } from "@ant-design/icons";
import { App, Button, Divider, Dropdown, Empty, Flex, Image, MenuProps, QRCode, Switch, Tooltip, Tree, Typography } from "antd";
import { UploadBuildBundleDrawer } from "app/components/drawers/UploadBuildBundleDrawer";
import { PlatformConstraints } from "lib/models/constraints";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { Index, InfiniteLoader, List, ListRowProps } from "react-virtualized";

class Store {
  showProps: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  setShowProps(value: boolean) {
    this.showProps = value;
  }
}


export const BundlesApplicationSection = observer((props: {}) => {
  const { appStore } = useGlobalStore();
  const { application } = appStore;
  const [store] = useState(() => new Store());

  return (
    <div className="space-y-6">
      {application && (
        <div className="space-y-6">
          <div className="rounded-xl border border-dashed shadow p-6">
            <Flex justify="space-between">
              <Flex className="space-x-2" align="center">
                {PlatformConstraints[application.platform].icon}

                <Typography.Text className="text-xl font-semibold">
                  Build Bundles
                </Typography.Text>
              </Flex>

              <UploadBuildBundleDrawer
                render={(open, close) => <Button onClick={open}>Upload</Button>}
              />
            </Flex>

            <Divider />

            <BuildList store={store} />
          </div>
        </div>
      )}
    </div>
  );
});

const BuildList = observer(({ store }: { store: Store }) => {
  const { appStore, buildStore } = useGlobalStore();
  const { application } = appStore;

  useEffect(() => {
    if (!application) return;
    buildStore.paging
      .init({
        appId: application.id,
      })
      .finally();
  }, [application, buildStore]);

  const isRowLoaded = useCallback(
    ({ index }: Index) => {
      console.log(index);
      return !!buildStore.paging.data[index];
    },
    [buildStore.paging.data],
  );

  const loadMoreRows = useCallback(
    () => buildStore.paging.next().finally(),
    [buildStore],
  );

  if (buildStore.paging.data.length === 0) {
    return <Empty />;
  }

  return (
    <>
      <Flex className="p-2">
        <Switch
          checkedChildren="Show Properties"
          unCheckedChildren="Hide Properties"
          defaultChecked={store.showProps}
          onChange={(checked) => store.setShowProps(checked)}
        />
      </Flex>

      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={buildStore.paging.total}
      >
        {({ onRowsRendered, registerChild }) => (
          <List
            height={600}
            width={Number.MAX_SAFE_INTEGER}
            rowCount={buildStore.paging.total}
            rowHeight={store.showProps ? 600 : 160}
            rowRenderer={(props) => (
              <div key={props.key} style={props.style}>
                <BuildListItem props={props} store={store} />
              </div>
            )}
            onRowsRendered={onRowsRendered}
            autoWidth
            ref={registerChild}
          />
        )}
      </InfiniteLoader>
    </>
  );
});

const BuildListItem = observer(({ props, store }: { props: ListRowProps, store: Store }) => {
  const { modal } = App.useApp();
  const { buildStore, appStore } = useGlobalStore();
  const { application } = appStore;
  const build = buildStore.paging.data[props.index] ?? buildStore.paging.data[0];

  const buildActionMenuItems = (buildId: string): MenuProps['items'] => [
    {
      key: 'view-details',
      label: <Button className="w-full !flex !justify-start hover:bg-gray-100 px-4" type="text" onClick={() => { }} icon={<EyeOutlined />}>View Details</Button>,
    },
    {
      key: 'download',
      label: <Button className="w-full !flex !justify-start hover:bg-gray-100 px-4" type="text" onClick={() => handleDownload(build.bundleUrl)} icon={<DownloadOutlined />}>Download</Button>,
    },
    {
      key: 'analyze',
      label: <Button className="w-full !flex !justify-start hover:bg-gray-100 px-4" type="text" onClick={() => handleAnalyze(buildId)} icon={<BarChartOutlined />} >Analyze</Button>,
    },
  ];

  const handleDownload = (url: string) => {
    window.open(url, "_blank");
  };

  const handleAnalyze = (buildId: string) => {
    modal
      .confirm({
        title: "Are you sure?",
        content: "That you want to analyze this build?",
        maskClosable: true,
        centered: true,
        onOk: () => {
          buildStore.analyze(buildId)
        }
      });
  };

  return (
    <Flex className="h-full w-full p-2">
      {build && application ? (
        <Flex className="bg-zinc-50 rounded-lg w-full p-3 shadow-md" vertical>
          <Flex gap={16} align="center" flex="0">
            <Image
              src={build.properties.icon || application.imageUrl}
              height="48px"
              preview={false}
            />

            <Flex vertical>
              <Typography.Text strong>
                {application.name} - {build.properties["VersionName"]}
              </Typography.Text>

              <Typography.Text type="secondary" className="text-xs">
                {new Date(build.createdAt).toLocaleString()}
              </Typography.Text>
            </Flex>

            <Dropdown menu={{ items: buildActionMenuItems(build.id) }} trigger={['click']}>
              <Button type="text" className="ml-auto" icon={<EllipsisOutlined />} />
            </Dropdown>
          </Flex>

          <Divider className="my-3" />

          {
            store.showProps &&
            <>
              <Flex flex="auto" className="relative" vertical>
                <div className="absolute inset-0 bg-white rounded-md shadow-sm p-2 overflow-auto">
                  <Tree
                    showLine
                    treeData={
                      Object.entries(build.properties)
                        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                        .map(([key, val]) => ({
                          key: key,
                          title: <>
                            <Typography.Text strong>{key}: </Typography.Text>
                            <Typography.Text>{val}</Typography.Text>
                          </>,
                          isLeaf: true,
                        }))
                    }
                  />
                </div>
              </Flex>

              <Divider className="my-3" />
            </>
          }

          <Flex gap={10}>

            <Tooltip
              placement="topLeft"
              title={<QRCode value={build.bundleUrl} bgColor="white" />}
            >
              <Button className="flex-1" size="large"
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => handleDownload(build.bundleUrl)}
              >
                Download Bundle
              </Button>
            </Tooltip>

            <Button className="flex-1" size="large"
              icon={<BarChartOutlined />}
              onClick={() => handleAnalyze(build.id)}
            >
              Analyze
            </Button>
          </Flex>

        </Flex>
      ) : (
        <div className="bg-slate-100 rounded-lg w-full animate-pulse" />
      )}
    </Flex>
  );
});
