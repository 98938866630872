import { AnalyticsPage } from "app/features/analytics/AnalyticsPage";
import { ApplicationPage } from "app/features/applications/ApplicationPage";
import { ApplicationsPage } from "app/features/applications/ApplicationsPage";
import { HomePage } from "app/features/home/HomePage";
import { LoginPage } from "app/features/login/LoginPage";
import { SettingsPage } from "app/features/settings/SettingsPage";
import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/applications",
    element: <ApplicationsPage />,
  },
  {
    path: "/analytics",
    element: <AnalyticsPage />,
  },
  {
    path: "/settings/:tab?/:adminTab?",
    element: <SettingsPage />,
  },
  {
    path: "/applications/:id/:tab?",
    element: <ApplicationPage />,
  },
]);

export default router;
