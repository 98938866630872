import agent from "api/agent";
import { EnsureOk } from "api/utils";
import { makeAutoObservable, runInAction } from "mobx";
import { CatchError } from "../misc/CatchError";
import { App, PlatformType } from "../models";

export class AppStore {
  application?: App;
  applications: App[] = [];
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  clearApplication() {
    this.application = undefined;
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  load(id: string) {
    return CatchError(async () => {
      this.setLoading(true);

      const response = await agent.application.get(id);

      EnsureOk(response);

      this.clearApplication();

      runInAction(() => {
        this.application = response.data;
      });
    }, this.setLoading.bind(this));
  }

  loadAll() {
    return CatchError(async () => {
      this.setLoading(true);

      const response = await agent.application.getAll();

      EnsureOk(response);

      runInAction(() => {
        this.applications = response.data?.data ?? [];
      });
    }, this.setLoading.bind(this));
  }

  delete(id: string) {
    return CatchError(async () => {
      const response = await agent.application.delete(id);

      EnsureOk(response);
    }, this.setLoading.bind(this));
  }

  update(
    id: string,
    data: {
      imageUrl?: string;
      image?: File;
      name?: string;
      properties?: { [key: string]: string };
    },
  ) {
    return CatchError(async () => {
      if (data.image) {
        const uploadResponse =
          await agent.cloudStorage.uploadFileWithPresignedUrl(
            `images/${crypto.randomUUID()}`,
            data.image,
          );
        EnsureOk(uploadResponse);
        data.imageUrl = uploadResponse.data!.url;
      }

      const response = await agent.application.update(id, {
        name: data.name,
        imageUrl: data.imageUrl,
        properties: data.properties,
      });

      EnsureOk(response);
    }, this.setLoading.bind(this));
  }

  create(data: { image: File | string; name: string; platform: PlatformType }) {
    return CatchError(async () => {
      let imageUrl = data.image;

      if (typeof imageUrl !== "string") {
        const uploadResponse =
          await agent.cloudStorage.uploadFileWithPresignedUrl(
            `images/${crypto.randomUUID()}`,
            imageUrl,
          );
        EnsureOk(uploadResponse);
        imageUrl = uploadResponse.data!.url;
      }

      const response = await agent.application.create({
        name: data.name,
        imageUrl: imageUrl,
        platformType: data.platform,
      });

      EnsureOk(response);
    }, this.setLoading.bind(this));
  }
}
