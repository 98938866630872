import {
  AppstoreOutlined,
  AreaChartOutlined,
  HomeOutlined,
  NotificationOutlined,
  SettingOutlined
} from "@ant-design/icons";
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Flex,
  Image,
  Row,
  Typography
} from "antd";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const navLinks = {
  home: {
    icon: <HomeOutlined />,
    title: "Home",
    to: "/",
  },
  applications: {
    icon: <AppstoreOutlined />,
    title: "Applications",
    to: "/applications",
  },
  analytics: {
    icon: <AreaChartOutlined />,
    title: "Analytics",
    to: "/analytics",
  },
  settings: {
    icon: <SettingOutlined />,
    title: "Settings",
    to: "/settings",
  },
};

interface Props extends React.PropsWithChildren {
  selection: string;
}

export const NavbarWrapper = observer((props: Props) => {
  const globalStore = useGlobalStore();

  return (
    <>
      <Row className="w-screen h-screen" wrap={false}>
        <Col xxl={5} xl={6} className="lg:flex-none">
          <Navbar {...props} />
        </Col>

        <div className="w-[1px] bg-gray-300" />

        <Col flex="auto">
          <div className="flex-1 max-h-screen overflow-y-auto">
            <Flex
              flex="initial"
              justify="space-between"
              align="center"
              className="p-6"
            >
              <div className="flex-1">
                <Typography.Title level={2}>
                  {globalStore.title}
                </Typography.Title>

                <Breadcrumb
                  items={[
                    {
                      title: "~",
                      path: "/",
                    },
                    ...globalStore.breadcrumb.map((value) => ({
                      title: value.title,
                      path: value.path,
                    })),
                  ]}
                  itemRender={(route) => (
                    <Link to={route.path ?? ""}>{route.title}</Link>
                  )}
                />
              </div>

              <div>
                <Button size="large" icon={<NotificationOutlined />} />
              </div>
            </Flex>

            <div className="p-6">{props.children}</div>
          </div>
        </Col>
      </Row>
    </>
  );
});

const Navbar = observer(({ selection }: Props) => {
  const { user } = useGlobalStore();

  return (
    <>
      <div className="flex-none">
        {/* Expanded */}
        <div className="max-lg:hidden p-6 space-y-5">
          <div className="px-6 flex justify-center">
            <Image src="/logo-ex.svg" preview={false} />
          </div>

          <div className="border rounded-xl flex items-center p-6">
            <div>
              <Avatar src={user?.avatarUrl || "/user-default-avatar.png"} size="large" />
            </div>

            <div className="pl-3">
              <Typography.Title level={5}>
                {user?.name}
              </Typography.Title>

              <Typography.Text type="secondary">
                {user?.email}
              </Typography.Text>
            </div>
          </div>

          <div className="border rounded-xl flex justify-center p-3 space-y-3 flex-col">
            {Object.entries(navLinks).map(([key, item]) => (
              <NavbarButton
                key={key}
                isSelected={key === selection}
                {...item}
              />
            ))}
          </div>
        </div>

        {/* Collapsed */}
        <div className="lg:hidden p-2 space-y-5">
          <div className="flex items-center p-1 justify-center">
            <Avatar src={user?.avatarUrl || "/user-default-avatar.png"} size="large" />
          </div>

          <div className="border rounded-xl flex justify-center p-3 space-y-3 flex-col">
            {Object.entries(navLinks).map(([key, item]) => (
              <NavbarButton
                key={key}
                isSelected={key === selection}
                minimal
                {...item}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
});

const NavbarButton = (props: {
  icon: React.ReactNode;
  title: string;
  isSelected: boolean;
  to: string;
  minimal?: boolean;
}) => {
  return (
    <Link to={props.to}>
      <div
        className={
          twMerge(
            "p-3 rounded-xl w-full select-none cursor-pointer",
            props.isSelected
              ? "active:bg-orange-300 hover:bg-orange-200 bg-the-one"
              : "active:bg-gray-200 hover:bg-gray-100",
          )
        }
      >
        <Typography.Text type="secondary">
          <div
            className={
              twMerge(
                "flex items-center space-x-4",
                props.isSelected && " text-white font-semibold"
              )
            }
          >
            <div className="aspect-square h-full flex justify-center items-center">
              <div className="text-xl">{props.icon}</div>
            </div>

            {
              !props.minimal &&
              <div className="text-lg">{props.title}</div>
            }
          </div>
        </Typography.Text>
      </div>
    </Link>
  );
};
