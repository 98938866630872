import axios from "axios";
import { BuildBundle } from "lib/models";
import { AxiosWrapper, BaseAPIMainConfig } from "./utils";

export const build = {
  get: (id: string) =>
    AxiosWrapper(
      axios.get<BuildBundle>(`/build-bundle/${id}`, BaseAPIMainConfig),
    ),

  create: (data: { appId: string; url: string }) =>
    AxiosWrapper(axios.post("/build-bundle", data, BaseAPIMainConfig)),

  analyze: (id: string) =>
    AxiosWrapper(
      axios.post<BuildBundle>(`/build-bundle/${id}/analyze`, null, BaseAPIMainConfig),
    ),
};
