import axios from "axios";
import { PagingRequest, PagingResponse, PromotionalGame } from "lib/models";
import { AxiosWrapper, BaseAPIMainConfig } from "./utils";

export const promotional = {
  getAll: (params?: PagingRequest) => AxiosWrapper(axios.get<PagingResponse<PromotionalGame>>("/promotional-game", {
    ...BaseAPIMainConfig,
    params: params
  })),

  delete: (id: string) =>
    AxiosWrapper(axios.delete(`/promotional-game/${id}`, BaseAPIMainConfig)),

  update: (
    id: string,
    data: {
      title?: string,
      genre?: string,
      imageUrl?: string,
      gameUrl?: string;
    },
  ) => AxiosWrapper(axios.put(`/promotional-game/${id}`, data, BaseAPIMainConfig)),

  create: (data: {
    title: string,
    genre: string,
    imageUrl: string,
    gameUrl?: string;
  }) => AxiosWrapper(axios.post("/promotional-game", data, BaseAPIMainConfig)),
};
