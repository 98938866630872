import {
  AndroidOutlined,
  AppleFilled,
  AppstoreAddOutlined,
  CloudOutlined,
  FireOutlined,
  GoogleOutlined,
  PieChartOutlined,
  RiseOutlined,
  RocketOutlined
} from "@ant-design/icons";
import { Button, Card, Col, Flex, Row, Typography } from "antd";
import { AppStore } from "lib/storage/AppStore";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const integrationIcons: Record<string, JSX.Element> = {
  "Firebase.Analytics": <FireOutlined />,
  "Firebase.Crashlytics": <FireOutlined />,
  "Firebase.Messaging": <GoogleOutlined />,
  "Firebase.RemoteConfig": <GoogleOutlined />,
  "Admob": <AndroidOutlined />,
  "AdColony": <CloudOutlined />,
  "AppLovin": <AppstoreAddOutlined />,
  "Mintegral": <RocketOutlined />,
  "LiftOff": <RiseOutlined />,
  "UnityAds": <CloudOutlined />,
  "InMobi": <AndroidOutlined />,
  "Pangle": <AppleFilled />,
  "DtExchange": <CloudOutlined />,
  "BigoAd": <AndroidOutlined />,
  "Yandex": <GoogleOutlined />,
  "Ogury": <PieChartOutlined />,
  "MyTarget": <AndroidOutlined />,
  "TopOn": <RocketOutlined />,
  "ChartBoost": <PieChartOutlined />,
};

class Store {
  store: {
    appStore: AppStore;
  };

  constructor(store: any) {
    this.store = store;
    makeAutoObservable(this);
  }

  getIsIntegrationActive(key: string) {
    const app = this.store.appStore.application;
    if (!app) return false;

    return app.properties[key] === "" + true;
  }

  switchIsIntegrationActive(key: string) {
    const app = this.store.appStore.application;
    if (!app) return false;

    app.properties[key] = "" + (app.properties[key] !== "" + true);
  }
}

interface Props { }

export default observer((props: Props) => {
  const globalStore = useGlobalStore();
  const [store] = useState(() => new Store(globalStore));
  const { appStore, supportedIntegrations } = globalStore;
  const { application } = appStore;
  return (
    <>
      <Row gutter={[8, 8]}>
        {supportedIntegrations.map((value) => (
          <Col key={value.key} xs={24} md={12} lg={8} xl={6}>
            <Card>
              <Flex
                vertical
                align="center"
                justify="space-between"
                className="h-[100px]"
              >
                <Flex vertical align="center">
                  {integrationIcons[value.key] || (
                    <CloudOutlined style={{ fontSize: 32 }} />
                  )}

                  <Typography.Text strong>{value.key}</Typography.Text>
                </Flex>

                <Button
                  size="large"
                  type={
                    store.getIsIntegrationActive(value.key)
                      ? "primary"
                      : "dashed"
                  }
                  onClick={() => {
                    if (!application) return;

                    store.switchIsIntegrationActive(value.key);

                    appStore
                      .update(application.id, {
                        properties: appStore.application!.properties,
                      })
                      .finally(() => appStore.load(application.id));
                  }}
                >
                  {store.getIsIntegrationActive(value.key)
                    ? "Active"
                    : "Inactive"}
                </Button>
              </Flex>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
});
