import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface Props {
    data: any[];
}

export const Chart: React.FC<Props> = ({ data }) => {
    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="linear" dataKey="Christiano" stroke="#8884d8" />
                <Line type="linear" dataKey="Messi" stroke="#82ca9d" />
                <Line type="linear" dataKey="LamineYamal" stroke="#ffc658" />
            </LineChart>
        </ResponsiveContainer>
    );
};
