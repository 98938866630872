import { Drawer } from "antd";
import React, { useCallback, useEffect, useState } from "react";

export interface IDrawerProps {
  render: (open: () => void, close: () => void) => React.ReactNode;
  children?:
  | React.ReactNode
  | ((open: () => void, close: () => void) => React.ReactNode);
  onOpenChanged?: (open: boolean) => void;
}

export const BaseDrawer = (props: IDrawerProps) => {
  const [open, setOpen] = useState(false);

  const triggerOpen = useCallback(() => setOpen(true), [setOpen]);
  const triggerClose = useCallback(() => setOpen(false), [setOpen]);

  useEffect(() => {
    if (props.onOpenChanged) props.onOpenChanged(open);
  }, [open, props]);

  return (
    <>
      {props.render(triggerOpen, triggerClose)}

      <Drawer
        title="Create new application"
        size="large"
        width={520}
        closable={false}
        onClose={triggerClose}
        open={open}
      >
        {typeof props.children === "function"
          ? props.children(triggerOpen, triggerClose)
          : props.children}
      </Drawer>
    </>
  );
}
