import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";
import { useGlobalStore } from "../storage/GlobalStore";

export const RouteGuard = observer(({ children }: any) => {
  const globalStore = useGlobalStore();
  const { isLoading, authStore, user } = globalStore;

  const jwt = authStore.getJwt();

  if (!user && jwt) {
    globalStore
      .load()
      .failure(() => {
        authStore.setJwt(null);
      })
      .finally();
  }

  if (!jwt) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {isLoading && (
        <div className="h-screen w-screen flex justify-center items-center">
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 64, color: "#FA8C16" }}
                spin
              />
            }
          />
        </div>
      )}

      <div>{children}</div>
    </>
  );
});
