import axios from "axios";
import { Organization, PagingRequest, PagingResponse } from "lib/models";
import { AxiosWrapper, BaseAPIMainConfig } from "./utils";

export const organization = {
  getAll: (params?: PagingRequest) =>
    AxiosWrapper(axios.get<PagingResponse<Organization>>("/organization", {
      ...BaseAPIMainConfig,
      params: params
    })),

  get: (id: string) =>
    AxiosWrapper(
      axios.get<Organization>(`/organization/${id}`, BaseAPIMainConfig),
    ),

  create: (data: { name: string; image: string }) =>
    AxiosWrapper(axios.post("/organization", data, BaseAPIMainConfig)),

  update: (
    id: string,
    data: {
      name?: string;
      image?: string;
    },
  ) => AxiosWrapper(axios.put(`/organization/${id}`, data, BaseAPIMainConfig)),

  delete: (id: string) =>
    AxiosWrapper(axios.delete(`/organization/${id}`, BaseAPIMainConfig)),
};
