import { PlusCircleFilled } from "@ant-design/icons";
import { Upload } from "antd";
import { observer } from "mobx-react-lite";
import { twMerge } from "tailwind-merge";

interface Props {
  label: string;
  file?: File;
  onChange: (file?: File) => void;
  accept?: string;
  isError?: boolean;
}

export const FileSelector = observer((props: Props) => {
  return (
    <>
      <Upload
        className={
          twMerge(
            "rounded-md",
            props.isError && "border-red-300 border-[2px]"
          )
        }
        customRequest={(_) => _}
        listType="picture-card"
        type="drag"
        maxCount={1}
        showUploadList={false}
        onChange={(info) => {
          props.onChange(info.file.originFileObj);
        }}
        accept={props.accept}
      >
        <button className="border-0 bg-none p-6" type="button">
          {props.file ? (
            <>
              <div>{props.file.name}</div>
            </>
          ) : (
            <>
              <PlusCircleFilled />
              <div>{props.label}</div>
            </>
          )}
        </button>
      </Upload>
    </>
  );
});
