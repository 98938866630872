import axios from "axios";
import { AxiosWrapper, BaseAPIMainConfig } from "./utils";

type PresignedUrlResponse = {
  presignedUrl: string;
  accessUrl: string;
};

type UploadResponse = {
  url: string;
};

export const cloudStorage = {
  getPresignedUrl: (key: string, contentType: string) =>
    AxiosWrapper(
      axios.get<PresignedUrlResponse>(`/cloud-storage/presigned-url`, {
        ...BaseAPIMainConfig,
        params: {
          key,
          contentType,
        },
      }),
    ),

  uploadFile: (key: string, file: File) => {
    const form = new FormData();
    form.append("file", file);
    form.append("key", `T1-Dashboard/${key}`);
    return AxiosWrapper(
      axios.post<UploadResponse>(
        "/cloud-storage/upload",
        form,
        BaseAPIMainConfig,
      ),
    );
  },

  async uploadFileWithPresignedUrl(key: string, file: File) {
    const contentType = file.type || "application/octet-stream";

    const getPresignedUrlResponse = await this.getPresignedUrl(
      key,
      contentType,
    );

    if (getPresignedUrlResponse.error || !getPresignedUrlResponse.data) {
      return {
        ok: false,
        data: null,
        error: getPresignedUrlResponse.error,
      };
    }

    const presignedUrl = getPresignedUrlResponse.data.presignedUrl;
    const accessUrl = getPresignedUrlResponse.data.accessUrl;

    const buffer = await file.arrayBuffer();

    await axios.put(presignedUrl, new Blob([buffer]), {
      headers: {
        "Content-Type": contentType,
      },
    });

    return {
      ok: true,
      data: {
        url: accessUrl,
      } as UploadResponse,
      error: undefined,
    };
  },
};
