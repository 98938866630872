import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { EnsureOk } from "../../api/utils";
import { CatchError } from "../misc/CatchError";
import { BuildBundle } from "../models";
import PagingStore from "./PagingStore";

export class BuildBundleStore {
  loading: boolean = false;
  buildBundles: BuildBundle[] = [];
  buildBundle?: BuildBundle;

  paging: PagingStore<BuildBundle, { appId: string }> = new PagingStore<
    BuildBundle,
    { appId: string }
  >(async ({ appId }: { appId: string }, index: number) => {
    const response = await agent.application.getBuilds(appId, {
      index: index,
      count: 10
    });

    return {
      data: response.data?.data ?? [],
      total: response.data?.max ?? 0,
    };
  });

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  loadAll(appId: string) {
    return CatchError(async () => {
      const response = await agent.application.getBuilds(appId);

      EnsureOk(response);

      runInAction(() => {
        this.buildBundles = response.data?.data ?? [];
      });
    }, this.setLoading.bind(this));
  }

  load(id: string) {
    return CatchError(async () => {
      const response = await agent.build.get(id);

      EnsureOk(response);

      runInAction(() => {
        this.buildBundle = response.data;
      });
    }, this.setLoading.bind(this));
  }

  upload(data: { appId: string; file: File | string }) {
    return CatchError(async () => {
      let fileUrl = data.file;

      if (typeof fileUrl !== "string") {
        const uploadResponse =
          await agent.cloudStorage.uploadFileWithPresignedUrl(
            `builds/${crypto.randomUUID()}`,
            fileUrl,
          );
        EnsureOk(uploadResponse);
        fileUrl = uploadResponse.data!.url;
      }

      const response = await agent.build.create({
        appId: data.appId,
        url: fileUrl,
      });

      EnsureOk(response);
    }, this.setLoading.bind(this));
  }

  analyze(id: string) {
    return CatchError(async () => {
      const response = await agent.build.analyze(id);

      EnsureOk(response);
    }, this.setLoading.bind(this));
  }
}
