import React from 'react';
import { Table } from 'antd';

interface Props {
    columns: any[];
    data: any[];
}

export const DataTable: React.FC<Props> = ({ columns, data }) => {
    return (
        <Table columns={columns} dataSource={data} scroll={{ x: true }} />
    );
};
