import { Button, Divider, Input, Typography } from "antd";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { Observer, observer } from "mobx-react-lite";
import { useState } from "react";
import { FileSelector } from "../FileSelector";
import { BaseDrawer, IDrawerProps } from "./BaseDrawer";

interface Props extends IDrawerProps { }

export const CreateAndroidKeystoreDrawer = observer((props: Props) => {
  class Store {
    name: string = "";
    keystoreFile?: File;
    pass: string = "";
    keyAlias: string = "";
    keyAliasPass: string = "";

    constructor() {
      makeAutoObservable(this);
    }

    setKeystoreFile(file?: File) {
      this.keystoreFile = file;
    }

    setName(name: string) {
      this.name = name;
    }

    setPass(password: string) {
      this.pass = password;
    }

    setKeyAlias(alias: string) {
      this.keyAlias = alias;
    }

    setKeyAliasPass(aliasPass: string) {
      this.keyAliasPass = aliasPass;
    }
  }

  const { ksStore } = useGlobalStore();
  const [store] = useState(() => new Store());

  return (
    <>
      <BaseDrawer
        render={props.render}
        onOpenChanged={() => {
          store.setName("");
          store.setKeystoreFile();
          store.setPass("");
          store.setKeyAlias("");
          store.setKeyAliasPass("");
        }}
      >
        {(open, close) => (
          <Observer>
            {() => (
              <>
                <div className="mb-4">
                  <Typography.Title level={5}>Name</Typography.Title>

                  <Input
                    size="large"
                    value={store.name}
                    onChange={(e) => store.setName(e.target.value)}
                  />
                </div>

                <Divider />

                <div className="mb-4">
                  <Typography.Title level={5}>Keystore file</Typography.Title>

                  <FileSelector
                    label="Upload Keystore"
                    file={store.keystoreFile}
                    onChange={(file) => store.setKeystoreFile(file)}
                    accept=".keystore"
                  />
                </div>

                <div className="mb-4">
                  <Typography.Title level={5}>Key Pass</Typography.Title>

                  <Input
                    type="password"
                    size="large"
                    value={store.pass}
                    onChange={(e) => store.setPass(e.target.value)}
                  />
                </div>

                <Divider />

                <div className="mb-4">
                  <Typography.Title level={5}>Key Alias</Typography.Title>

                  <Input
                    size="large"
                    value={store.keyAlias}
                    onChange={(e) => store.setKeyAlias(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <Typography.Title level={5}>Key Alias Pass</Typography.Title>

                  <Input
                    type="password"
                    size="large"
                    value={store.keyAliasPass}
                    onChange={(e) => store.setKeyAliasPass(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <Button
                    onClick={() =>
                      store.keystoreFile &&
                      ksStore
                        .create({
                          name: store.name,
                          keyAliasPass: store.keyAliasPass,
                          keyAlias: store.keyAlias,
                          keystore: store.keystoreFile,
                          keystorePass: store.pass,
                        })
                        .success(() =>
                          ksStore.loadAll().finally(close),
                        )
                    }
                    loading={ksStore.isLoading}
                  >
                    Create
                  </Button>
                </div>
              </>
            )}
          </Observer>
        )}
      </BaseDrawer>
    </>
  );
});
