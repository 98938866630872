import { Button, Input, Select, Typography } from "antd";
import { PlatformType } from "lib/models";
import { PlatformConstraints } from "lib/models/constraints";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { Observer, observer } from "mobx-react-lite";
import { useState } from "react";
import { FileSelector } from "../FileSelector";
import { BaseDrawer, IDrawerProps } from "./BaseDrawer";

interface Props extends IDrawerProps { }

export const CreateApplicationDrawer = observer((props: Props) => {
  class Store {
    name: string = "";
    image?: File;
    platformType: PlatformType = PlatformType.Android;

    constructor() {
      makeAutoObservable(this);
    }

    setImage(image?: File) {
      this.image = image;
    }

    setName(name: string) {
      this.name = name;
    }

    setPlatformType(platform: PlatformType) {
      this.platformType = platform;
    }
  }

  const [store] = useState(() => new Store());
  const { appStore } = useGlobalStore();

  return (
    <>
      <BaseDrawer
        render={props.render}
        onOpenChanged={(open) => {
          store.setName("");
          store.setImage();
          store.setPlatformType(PlatformType.Android);
        }}
      >
        {(open, close) => (
          <Observer>
            {() => (
              <>
                <div className="mb-4">
                  <Typography.Title level={5}>Name</Typography.Title>

                  <Input
                    size="large"
                    value={store.name}
                    status={store.name.trim() ? undefined : "error"}
                    onChange={(e) => store.setName(e.target.value)}
                    count={{
                      max: 64,
                      show: true
                    }}
                  />
                </div>

                <div className="mb-4">
                  <Typography.Title level={5}>Image</Typography.Title>

                  <FileSelector
                    label="Upload App Image"
                    file={store.image}
                    onChange={(file) => store.setImage(file)}
                    accept="image/*"
                    isError={!store.image}
                  />
                </div>

                <div className="mb-4">
                  <Typography.Title level={5}>Platform</Typography.Title>

                  <Select
                    className="w-full"
                    value={store.platformType}
                    status={Object.values(PlatformType).includes(store.platformType) ? undefined : "error"}
                    onChange={store.setPlatformType.bind(store)}
                    options={Object.values(PlatformConstraints).map(
                      (value) => ({
                        label: (
                          <div key={value.id} className="flex items-center space-x-2">
                            {value.icon}
                            <span>{value.name}</span>
                          </div>
                        ),
                        value: value.type,
                      }),
                    )}
                  />
                </div>

                <div className="mb-4">
                  <Button
                    onClick={() =>
                      store.image &&
                      appStore
                        .create({
                          name: store.name,
                          image: store.image,
                          platform: store.platformType,
                        })
                        .success(() => appStore.loadAll().finally(close))
                    }
                    loading={appStore.isLoading}
                  >
                    Create
                  </Button>
                </div>
              </>
            )}
          </Observer>
        )}
      </BaseDrawer>
    </>
  );
});
