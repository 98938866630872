export class Enum {
  static object<TEnum extends Record<string, string | number>>(e: TEnum): { [key: string]: number } {
    return Object
      .entries(e)
      .filter(([key]) => isNaN(Number(key)))
      .reduce((out, [key, val]) => out[key] = val, {} as any)
  }

  static entries<TEnum extends Record<string, string | number>>(e: TEnum): Array<[string, number]> {
    return Object
      .entries(e)
      .filter(([key, val]) => isNaN(Number(key)) && !isNaN(Number(val)))
      .map(([key, val]) => [key, Number(val)])
  }
}
