import router from "lib/router";
import { PreventRefreshProvider } from "lib/router/PreventRefresh";
import { GlobalContextProvider } from "lib/storage/GlobalStore";
import { observer } from "mobx-react-lite";
import { RouterProvider } from "react-router-dom";

interface Props { }

export const MainLayout = observer((props: Props) => {
  return (
    <>
      <GlobalContextProvider>
        <PreventRefreshProvider>
          <div className="h-screen w-screen">
            <RouterProvider router={router} />
          </div>
        </PreventRefreshProvider>
      </GlobalContextProvider>
    </>
  );
});
