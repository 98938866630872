import axios from "axios";
import { PagingRequest, PagingResponse, User } from "lib/models";
import { AxiosWrapper, BaseAPIMainConfig } from "./utils";

export const user = {
  getCurrentUserInfo: () =>
    AxiosWrapper(axios.get<User>("/user/me", BaseAPIMainConfig)),

  setCurrentUserInfo: (data: {
    name?: string;
    avatar?: string;
    password?: string;
  }) => AxiosWrapper(axios.put("/user/me", data, BaseAPIMainConfig)),

  getAll: (params?: PagingRequest) => AxiosWrapper(axios.get<PagingResponse<User>>("/user", {
    ...BaseAPIMainConfig,
    params: params
  })),

  get: (id: string) =>
    AxiosWrapper(axios.get<User>(`/user/${id}`, BaseAPIMainConfig)),

  delete: (id: string) =>
    AxiosWrapper(axios.delete(`/user/${id}`, BaseAPIMainConfig)),

  updateUserInfo: (
    id: string,
    data: {
      orgId?: string;
      roleId?: string;
    },
  ) => AxiosWrapper(axios.put(`/user/${id}`, data, BaseAPIMainConfig)),

  create: (data: {
    email: string;
    name: string;
    password: string;
    avatar?: string;
    orgId?: string;
    roleId?: string;
  }) => AxiosWrapper(axios.post("/user", data, BaseAPIMainConfig)),
};
