import { makeAutoObservable, runInAction } from "mobx";

export default class PagingStore<TData = any, TArg = any> {
  data: TData[] = [];
  total: number = 1000;
  isLoading: boolean = false;

  args?: TArg;

  private readonly load: (
    args: TArg,
    index: number,
  ) => Promise<{ data: TData[]; total: number }>;

  constructor(
    load: (
      args: TArg,
      index: number,
    ) => Promise<{ data: TData[]; total: number }>,
  ) {
    makeAutoObservable(this);
    this.load = load;
  }

  clear() {
    this.data = [];
    this.total = 0;
    this.args = undefined;
  }

  async init(args: TArg) {
    this.clear();
    this.args = args;

    const result = await this.load(args, 0);
    runInAction(() => {
      this.total = result.total;
      this.data = this.data.concat(result.data);
    });
  }

  async next() {
    if (!this.args) return;

    const result = await this.load(this.args, this.data.length);

    runInAction(() => {
      this.total = result.total;
      this.data = this.data.concat(result.data);
    });
  }
}
