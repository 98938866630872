import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { createContext, useContext, useEffect, useState } from "react";

type Condition = () => boolean;

class PreventRefreshState {
    conditions: { [key: string]: Condition } = {};

    constructor() {
        makeObservable(
            this,
            {
                conditions: observable,
                addCondition: action,
                remCondition: action,
            }
        )
    }

    addCondition(key: string, condition: Condition) {
        this.conditions[key] = condition;
    }

    remCondition(key: string) {
        delete this.conditions[key]
    }

    get blocked() {
        return !!Object.values(this.conditions).find(condition => condition());
    }
}

const PreventRefreshContext = createContext<PreventRefreshState | null>(null);

export const PreventRefreshProvider = observer(({ children }: React.PropsWithChildren) => {
    const [state] = useState(() => new PreventRefreshState());

    useEffect(() => {
        const beforeUnload = (e: BeforeUnloadEvent) => {
            if (state.blocked) e.preventDefault();
            return true;
        }

        window.addEventListener("beforeunload", beforeUnload);
        return () => window.removeEventListener("beforeunload", beforeUnload);
    }, [state]);

    return (
        <PreventRefreshContext.Provider value={state}>
            {children}
        </PreventRefreshContext.Provider>
    )
})

export const usePreventRefresh = (condition: Condition) => {
    const state = useContext(PreventRefreshContext);

    useEffect(() => {
        if (!state) return;

        const key = crypto.randomUUID();

        state.addCondition(key, condition);

        return () => state.remCondition(key);
    }, [condition, state]);
};
