import { Button, Input, Select, Typography } from "antd";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { Observer, observer } from "mobx-react-lite";
import { useState } from "react";
import { FileSelector } from "../FileSelector";
import { BaseDrawer, IDrawerProps } from "./BaseDrawer";

interface Props extends IDrawerProps {
}

export const CreateUserDrawer = observer((props: Props) => {
  class Store {
    open: boolean = false;
    email: string = "";
    password: string = "";
    name: string = "";
    image?: File;
    roleId?: string;
    orgId?: string;

    constructor() {
      makeAutoObservable(this);
    }

    setImage(image?: File) {
      this.image = image;
    }

    setEmail(email: string) {
      this.email = email;
    }

    setPassword(email: string) {
      this.password = email;
    }

    setName(name: string) {
      this.name = name;
    }

    setRoleId(id?: string) {
      this.roleId = id;
    }

    setOrgId(id?: string) {
      this.orgId = id;
    }

    setOpen(isOpen: boolean) {
      this.open = isOpen;
    }

    get valid() {
      return this.email.trim() && this.password.trim() && this.name.trim();
    }
  }

  const [store] = useState(() => new Store());
  const { userStore, orgStore, roleStore } = useGlobalStore();

  return (
    <>
      <BaseDrawer
        render={props.render}
        onOpenChanged={(open) => {
          store.setPassword("");
          store.setName("");
          store.setImage();
          store.setEmail("");
          store.setRoleId();
          store.setOrgId();

          roleStore.loadAll().finally();
          orgStore.loadAll().finally();
        }}>
        {
          (open, close) => (
            <Observer>
              {
                () => (
                  <>
                    <div className="mb-4">
                      <Typography.Title level={5}>Email</Typography.Title>

                      <Input
                        size="large"
                        value={store.email}
                        status={store.email.trim() ? undefined : "error"}
                        onChange={(e) => store.setEmail(e.target.value)}
                        count={{
                          max: 64,
                          show: true
                        }}
                      />
                    </div>

                    <div className="mb-4">
                      <Typography.Title level={5}>Name</Typography.Title>

                      <Input
                        size="large"
                        value={store.name}
                        status={store.name.trim() ? undefined : "error"}
                        onChange={(e) => store.setName(e.target.value)}
                        count={{
                          max: 64,
                          show: true
                        }}
                      />
                    </div>

                    <div className="mb-4">
                      <Typography.Title level={5}>Password</Typography.Title>

                      <Input
                        size="large"
                        value={store.password}
                        onChange={(e) => store.setPassword(e.target.value)}
                        type="password"
                      />
                    </div>

                    <div className="mb-4">
                      <Typography.Title level={5}>Avatar</Typography.Title>

                      <FileSelector
                        label="Upload User Image"
                        file={store.image}
                        onChange={(file) => store.setImage(file)}
                      />
                    </div>

                    <div className="mb-4">
                      <Typography.Title level={5}>Role</Typography.Title>

                      <Select
                        allowClear
                        options={roleStore.roles.map((role) => ({
                          label: role.name,
                          value: role.id,
                        }))}
                        className="w-full"
                        size="large"
                        onChange={(value) => store.setRoleId(value)}
                        value={store.roleId}
                      />
                    </div>

                    <div className="mb-4">
                      <Typography.Title level={5}>Organization</Typography.Title>

                      <Select
                        allowClear
                        options={orgStore.organizations.map((organization) => ({
                          label: organization.name,
                          value: organization.id,
                        }))}
                        className="w-full"
                        size="large"
                        onChange={(value) => store.setOrgId(value)}
                        value={store.orgId}
                      />
                    </div>

                    <div className="mb-4">
                      <Button
                        onClick={() => userStore.create({
                          image: store.image,
                          email: store.email,
                          name: store.name,
                          password: store.password,
                          orgId: store.orgId,
                          roleId: store.roleId,
                        }).then(() => userStore.loadAll())}
                        loading={userStore.isLoading}
                        disabled={!store.valid}
                      >
                        Create
                      </Button>
                    </div>
                  </>
                )
              }
            </Observer>
          )
        }
      </BaseDrawer>
    </>
  );
});
