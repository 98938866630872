export {};

declare global {
  interface Promise<T> {
    success(callback: () => any): Promise<T>;

    failure(callback: () => any): Promise<T>;
  }
}

// eslint-disable-next-line no-extend-native
Promise.prototype.success = async function (callback: () => any) {
  let value = await this;
  if (value) callback();
  return value;
};

// eslint-disable-next-line no-extend-native
Promise.prototype.failure = async function (callback: () => any) {
  let value = await this;
  if (!value) callback();
  return value;
};
