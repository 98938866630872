import { NavbarWrapper } from "app/components/GlobalNavbar";
import { RouteGuard } from "lib/router/RouteGuard";
import { useBreadcrumb } from "lib/storage/GlobalStore";
import { observer } from "mobx-react-lite";

interface Props { }

export const HomePage = observer((props: Props) => {
  useBreadcrumb("Home", []);

  return (
    <>
      <RouteGuard>
        <NavbarWrapper selection="home">
          <></>
        </NavbarWrapper>
      </RouteGuard>
    </>
  );
});
