import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, DatePicker, Flex, Popconfirm, Select, Table, Typography } from "antd";
import { CreateUserAccessRuleDrawer } from "app/components/drawers/CreateUserAccessRuleDrawer";
import dayjs from "dayjs";
import { Enum } from "lib/misc/common.extension";
import {
  ExpiredActionType,
  RuleType,
  UserAccessRule
} from "lib/models";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";


interface Props { }

export const AdminSettingsUserAccessRuleSection = observer((props: Props) => {
  const { uarStore } = useGlobalStore();

  const reloadRules = useCallback(() => uarStore.loadAll(), [uarStore]);

  useEffect(() => {
    reloadRules();
  }, [reloadRules]);

  const isLoading = uarStore.isLoading;

  return (
    <>
      <Typography.Title level={3}>Manage</Typography.Title>

      <Typography.Text>
        User Access Rules is a set of rules, that each rule must have a pattern. <br />
        This pattern is in regex format, and used to do somethings automatically with any user, <br />
        who have the email matches with the pattern.
      </Typography.Text>

      <Flex justify="flex-end">
        <CreateUserAccessRuleDrawer
          render={(open, close) => (
            <Button size="large" onClick={open} type="primary" icon={<PlusCircleFilled />}>
              CREATE
            </Button>
          )}
        />
      </Flex>

      <div className="my-4" />

      <Table
        columns={[
          {
            title: "Pattern",
            dataIndex: "pattern",
          },
          {
            title: "Expired At",
            dataIndex: "expiredAt",
            render: (value: string | undefined, record) => (
              <DatePicker
                style={{
                  width: "100%"
                }}
                size="large"
                showTime={{
                  format: 'HH:mm',
                  use12Hours: true,
                  showNow: true,
                }}
                format={"YYYY-MM-DD HH:mm A"}
                onChange={(date) => {
                  uarStore
                    .update(record.id, date ? { expiredAt: date.toISOString() } : { removeExpiredAt: true })
                    .then(reloadRules)
                }}
                {...dayjs(value).isValid() ? { defaultValue: dayjs(value) } : {}}
              />
            )
          },
          {
            title: "Expired Action",
            dataIndex: "expiredAction",
            render: (value: string | undefined, record) => (
              <Select
                options={
                  Enum.entries(ExpiredActionType)
                    .map(([key, value]) => ({
                      label: key,
                      value: value,
                    }))
                }
                value={value}
                className="w-full"
                size="large"
                onSelect={(expiredAction) =>
                  uarStore
                    .update(record.id, { expiredAction: expiredAction as unknown as ExpiredActionType })
                    .then(reloadRules)
                }
                onClear={() =>
                  uarStore
                    .update(record.id, { expiredAction: undefined })
                    .then(reloadRules)
                }
              />
            ),
          },
          {
            title: "Type",
            dataIndex: "type",
            render: (value: string | undefined, record) => (
              <Select
                options={
                  Enum.entries(RuleType)
                    .map(([key, value]) => ({
                      label: key,
                      value: value,
                    }))
                }
                value={value}
                className="w-full"
                size="large"
                onSelect={(type) =>
                  uarStore
                    .update(record.id, { type: type as unknown as RuleType })
                    .then(reloadRules)
                }
                onClear={() =>
                  uarStore
                    .update(record.id, { type: undefined })
                    .then(reloadRules)
                }
              />
            ),
          },
          {
            title: "Action",
            render: (record: UserAccessRule) => (
              <Popconfirm
                title="Are you sure?"
                onConfirm={() =>
                  uarStore.delete(record.id).then(reloadRules)
                }
              >
                <Button
                  size="large"
                  icon={<DeleteOutlined />}
                  loading={isLoading}
                />
              </Popconfirm>
            ),
            width: 60,
            align: "center",
          }
        ]}
        dataSource={uarStore.rules}
        bordered
        size="large"
        loading={isLoading}
      />
    </>
  );
});
