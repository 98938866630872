import { GoogleOAuthProvider } from "@react-oauth/google";
import { App, ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import { MainLayout } from "./app/layout/MainLayout";
import "./index.css";
import "./lib/misc/boolean-promise.extension";
import "./lib/misc/common.extension";
import "./lib/models/env";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT ?? ""}>
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: "linear-gradient(180deg, #FF7B1A, #FF1F38)",
                        colorPrimaryHover: "linear-gradient(185deg, #FF7B1A, #FF1F38)",
                        colorPrimaryActive: "linear-gradient(180deg, #FF7B1A, #FF1F38)",
                    },
                },
            }}
        >
            <App>
                <MainLayout />
            </App>
        </ConfigProvider>
    </GoogleOAuthProvider>,
);
