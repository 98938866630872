import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { EnsureOk } from "../../api/utils";
import { CatchError } from "../misc/CatchError";
import { AndroidKeystore } from "../models";

export class AndroidKeystoreStore {
  keystores: AndroidKeystore[] = [];
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  loadAll() {
    return CatchError(async () => {
      const response = await agent.androidKeystore.getAll();

      EnsureOk(response);

      runInAction(() => {
        this.keystores = response.data?.data ?? [];
      });
    }, this.setLoading.bind(this));
  }

  create(data: {
    name: string;
    keystore: string | File;
    keystorePass: string;
    keyAlias: string;
    keyAliasPass: string;
  }) {
    return CatchError(async () => {
      if (!data.keystore) {
        throw new Error("Keystore not found");
      }

      let keystoreUrl = data.keystore;

      if (typeof keystoreUrl !== "string") {
        const uploadResponse =
          await agent.cloudStorage.uploadFileWithPresignedUrl(
            `keystores/${crypto.randomUUID()}`,
            keystoreUrl,
          );
        EnsureOk(uploadResponse);
        keystoreUrl = uploadResponse.data!.url;
      }

      const response = await agent.androidKeystore.create({
        name: data.name,
        keystoreUrl: keystoreUrl,
        keystorePass: data.keystorePass,
        keyAlias: data.keyAlias,
        keyAliasPass: data.keyAliasPass,
      });

      EnsureOk(response);
    }, this.setLoading.bind(this));
  }

  delete(id: string) {
    return CatchError(async () => {
      const response = await agent.androidKeystore.delete(id);

      EnsureOk(response);
    }, this.setLoading.bind(this));
  }
}
