import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Flex, Image, Table, Typography } from "antd";
import { Popconfirm } from "antd/lib";
import { CreateOrganizationDrawer } from "app/components/drawers/CreateOrganizationDrawer";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

interface Props { }

export const AdminSettingsOrganizationsSection = observer((props: Props) => {
  const { orgStore } = useGlobalStore();

  useEffect(() => {
    orgStore.loadAll().finally();
  }, [orgStore]);

  return (
    <>
      <Typography.Title level={3}>Manage</Typography.Title>

      <Typography.Text>
        You can manage all organizations in this system here.
      </Typography.Text>

      <Flex justify="flex-end">
        <CreateOrganizationDrawer render={
          (open, close) => (
            <Button size="large" onClick={open} type="primary" icon={<PlusCircleFilled />}>
              CREATE
            </Button>
          )
        }
        />
      </Flex>

      <div className="my-4" />

      <Table
        columns={[
          {
            width: 20,
            align: "center",
          },
          {
            title: "Icon",
            dataIndex: "image",
            width: 100,
            render: (value) => value && <Image src={value} />,
          },
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Members",
            dataIndex: "members",
          },
          {
            title: "Action",
            render: (value) =>
              value.id && (
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() =>
                    orgStore
                      .delete(value.id)
                      .then(() => orgStore.loadAll())
                  }
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              ),
            width: 60,
            align: "center",
          },
        ]}
        dataSource={orgStore.organizations.map((value) => ({
          ...value,
          members: value.users?.length,
          children: value.users?.map((member) => ({
            image: null,
            name: null,
            members: member.name,
          })),
        }))}
        bordered
        size="large"
        loading={orgStore.isLoading}
      />
    </>
  );
});
