import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Flex, Image, Select, Table } from "antd";
import { Popconfirm } from "antd/lib";
import { CreateUserDrawer } from "app/components/drawers/CreateUserDrawer";
import {
  PermissionType, User
} from "lib/models";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";


interface Props { }

export const AdminSettingsUsersSection = observer((props: Props) => {
  const { userStore, roleStore, orgStore } = useGlobalStore();

  useEffect(() => {
    userStore.loadAll().finally();
    roleStore.loadAll().finally();
    orgStore.loadAll().finally();
  }, [orgStore, roleStore, userStore]);

  const reloadUsers = useCallback(() => { userStore.loadAll() }, [userStore]);
  const isLoading = userStore.isLoading && roleStore.isLoading && orgStore.isLoading;

  return (
    <>
      <Flex justify="flex-end">
        <CreateUserDrawer
          render={(open, close) => (
            <Button size="large" onClick={open} type="primary" icon={<PlusCircleFilled />}>
              CREATE
            </Button>
          )}
        />
      </Flex>

      <div className="my-4" />

      <Table
        columns={[
          {
            title: "Avatar",
            dataIndex: "avatar",
            width: 100,
            render: (value) => (
              <Image
                src={value}
                fallback={"/icon.png"}
                width={64}
                height={64}
              />
            ),
            align: "center",
          },
          {
            title: "Email",
            dataIndex: "email",
          },
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Role",
            dataIndex: "roleId",
            render: (value: string | undefined, record) => (
              <Select
                options={roleStore.roles.map((role) => ({
                  label: role.name,
                  value: role.id,
                }))}
                value={value}
                className="w-full"
                size="large"
                allowClear
                onSelect={(roleId) =>
                  userStore
                    .update(record.id, { roleId })
                    .then(reloadUsers)
                }
                onClear={() =>
                  userStore
                    .update(record.id, { roleId: "" })
                    .then(reloadUsers)
                }
              />
            ),
          },
          {
            title: "Organization",
            dataIndex: "orgId",
            render: (value: string | undefined, record) => (
              <Select
                options={orgStore.organizations.map((org) => ({
                  label: org.name,
                  value: org.id,
                }))}
                value={value}
                className="w-full"
                size="large"
                allowClear
                onSelect={(orgId) =>
                  userStore
                    .update(record.id, { orgId })
                    .then(reloadUsers)
                }
                onClear={() =>
                  userStore
                    .update(record.id, { orgId: "" })
                    .then(reloadUsers)
                }
              />
            ),
          },
          {
            title: "Action",
            render: (value: User) => (
              <>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() =>
                    userStore.delete(value.id).then(reloadUsers)
                  }
                >
                  <Button
                    size="large"
                    icon={<DeleteOutlined />}
                    loading={isLoading}
                    disabled={value.role?.permissions.includes(
                      PermissionType.All,
                    )}
                  />
                </Popconfirm>
              </>
            ),
            width: 60,
            align: "center",
          },
        ]}
        dataSource={userStore.users}
        bordered
        size="large"
        loading={isLoading}
      />
    </>
  );
});
