import { makeAutoObservable } from "mobx";
import agent from "../../api/agent";
import { EnsureOk } from "../../api/utils";
import { CatchError } from "../misc/CatchError";

export class AuthStore {
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  getJwt() {
    return localStorage.getItem("jwt");
  }

  setJwt(value: string | null) {
    if (!value) {
      localStorage.removeItem("jwt");
      return;
    }

    localStorage.setItem("jwt", value);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  loginCredentials(data: { email: string; password: string }) {
    return CatchError(async () => {
      const response = await agent.auth.loginCredentials(data);

      this.setJwt(null);

      EnsureOk(response);

      this.setJwt(response.data!.token);
    }, this.setLoading.bind(this));
  }

  loginGoogle(data: { jwt?: string }) {
    return CatchError(async () => {
      if (!data.jwt) {
        throw new Error("Invalid JWT");
      }

      const response = await agent.auth.loginGoogle({
        jwt: data.jwt,
      });

      this.setJwt(null);

      EnsureOk(response);

      this.setJwt(response.data!.token);
    }, this.setLoading.bind(this));
  }
}
