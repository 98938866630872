import axios from "axios";
import { PagingRequest, PagingResponse, PermissionType, Role } from "lib/models";
import { AxiosWrapper, BaseAPIMainConfig } from "./utils";

export const role = {
  getAll: (params?: PagingRequest) => AxiosWrapper(axios.get<PagingResponse<Role>>("/role", {
    ...BaseAPIMainConfig,
    params: params
  })),

  delete: (id: string) =>
    AxiosWrapper(axios.delete(`/role/${id}`, BaseAPIMainConfig)),

  get: (id: string) =>
    AxiosWrapper(axios.get<Role>(`/role/${id}`, BaseAPIMainConfig)),

  update: (
    id: string,
    data: {
      name?: string;
      permissions?: PermissionType[];
    },
  ) => AxiosWrapper(axios.put(`/role/${id}`, data, BaseAPIMainConfig)),

  create: (data: { name: string; permissions: PermissionType[] }) =>
    AxiosWrapper(axios.post("/role", data, BaseAPIMainConfig)),
};
