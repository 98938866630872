export interface BaseEntity {
  id: string;
  createdAt: string;
  updatedAt: string;
};

export interface PromotionalGame extends BaseEntity {
  title: string,
  genre: string,
  imageUrl: string,
  gameUrl?: string;
};

export interface Organization extends BaseEntity {
  name: string;
  imageUrl: string;
  users?: User[];
};

export interface User extends BaseEntity {
  email: string;
  name: string;
  avatarUrl: string;
  roleId?: string;
  orgId?: string;
  role?: Role;
  org?: Organization;
  favoriteApps?: App[];
};

export interface Role extends BaseEntity {
  name: string;
  permissions: PermissionType[];
  users?: User[];
};

export interface AndroidKeystore extends BaseEntity {
  name: string;
  keyStoreUrl: string;
  keyStorePassword: string;
  keyAlias: string;
  keyPassword: string;
  applications?: App[];
};

export enum PermissionType {
  All,
  CreateApp,
  ReadApp,
  UpdateApp,
  DeleteApp,
}

export enum PlatformType {
  Android,
  IOS,
  Windows,
  WebGL,
  OSX,
}

export interface App extends BaseEntity {
  name: string;
  imageUrl: string;
  platform: PlatformType;
  properties: { [key: string]: string };
  androidKeystoreId?: string;
  androidKeystore?: AndroidKeystore;
  buildBundles?: BuildBundle[];
  favoritedByUsers?: User[];
};

export interface BuildBundle extends BaseEntity {
  bundleUrl: string;
  properties: { [key: string]: string };
  appId: string;
  application?: App;
  result: number;
};

export interface UserAccessRule extends BaseEntity {
  pattern: string;
  expiredAt: string;
  expiredAction: ExpiredActionType;
  type: RuleType;
}

export interface PagingResponse<T> {
  data: T[];
  max: number;
}

export interface PagingRequest {
  index?: number;
  count?: number;
}

export enum RuleType {
  Allow,
  Disallow,
  None,
}

export enum ExpiredActionType {
  MoveToDisabled,
  MoveToBlacklist,
  Delete,
}

export enum TheOneTaskStatus {
  Pending = "pending",
  Running = "running",
  Success = "success",
  Failed = "failed",
}
