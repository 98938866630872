import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Avatar, Button, Flex, Input, Table, Tooltip } from "antd";
import { Popconfirm } from "antd/lib";
import { CreateApplicationDrawer } from "app/components/drawers/CreateApplicationDrawer";
import { NavbarWrapper } from "app/components/GlobalNavbar";
import { PlatformType } from "lib/models";
import { PlatformConstraints } from "lib/models/constraints";
import { usePreventRefresh } from "lib/router/PreventRefresh";
import { RouteGuard } from "lib/router/RouteGuard";
import {
  useBreadcrumb,
  useGlobalStore
} from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface Props { }

export const ApplicationsPage = observer((props: Props) => {
  class Store {
    filterByNameValue: string = "";

    constructor() {
      makeAutoObservable(this);
    }

    setFilterByNameValue(value: string) {
      this.filterByNameValue = value;
    }
  }

  const [store] = useState(() => new Store());
  const { appStore } = useGlobalStore();

  useEffect(() => {
    appStore.loadAll().finally();
  }, [appStore]);

  useBreadcrumb("Applications", [
    {
      title: "Applications",
      path: "/applications",
    },
  ]);

  usePreventRefresh(() => {
    return appStore.isLoading;
  });

  return (
    <>
      <RouteGuard>
        <NavbarWrapper selection="applications">
          <>
            <Flex vertical>
              <Flex
                flex="none"
                justify="space-between"
                align="center"
                className="pb-6"
              >
                <div>
                  <Input
                    size="large"
                    placeholder="Filter by name"
                    value={store.filterByNameValue}
                    onChange={(e) => store.setFilterByNameValue(e.target.value)}
                  />
                </div>

                <CreateApplicationDrawer
                  render={(open, close) => (
                    <Button
                      icon={<PlusCircleFilled />}
                      size="large"
                      type="primary"
                      onClick={open}
                    >
                      CREATE
                    </Button>
                  )}
                />
              </Flex>

              <Table
                className="flex-auto"
                columns={[
                  {
                    title: "Name",
                    dataIndex: "name",
                    showSorterTooltip: {
                      target: "full-header",
                    },
                    sorter: (a, b) => a.name.localeCompare(b.name),
                    render: (value, record) => (
                      <Flex align="center" gap={10}>
                        <Avatar src={record.imageUrl} size="large" shape="square" />
                        <>
                          {
                            record.id ? (
                              <Link to={`/applications/${record.id}`}>
                                {record.name}
                              </Link>
                            ) : (
                              <div>{record.name}</div>
                            )
                          }
                        </>
                      </Flex>
                    ),
                  },
                  {
                    title: "Platform",
                    dataIndex: "platform",
                    render: (value: PlatformType) => (
                      <Tooltip title={PlatformConstraints[value].name}>
                        {PlatformConstraints[value].icon}
                      </Tooltip>
                    ),
                    onFilter: (value, record) => record.platform === value,
                    filters: Object.values(PlatformConstraints).map(
                      (platform) => ({
                        value: platform.id,
                        text: platform.name,
                      }),
                    ),
                    width: 48,
                    align: "center"
                  },
                  {
                    title: "Action",
                    render: (value) =>
                      value.id && (
                        <Popconfirm
                          title="Delete Application"
                          description="Are you sure you want to delete this application?"
                          onConfirm={() =>
                            appStore
                              .delete(value.id)
                              .finally(() => appStore.loadAll())
                          }
                        >
                          <Button
                            type="dashed"
                            icon={<DeleteOutlined />}
                            size="large"
                          />
                        </Popconfirm>
                      ),
                    width: 60,
                  },
                ]}
                dataSource={appStore.applications
                  .filter((value) =>
                    value.name
                      .toLowerCase()
                      .includes(store.filterByNameValue.toLowerCase()),
                  )
                  .map((app) => ({
                    key: app.id,
                    ...app,
                  }))}
                showSorterTooltip={{ target: "sorter-icon" }}
                bordered
              />
            </Flex>
          </>
        </NavbarWrapper>
      </RouteGuard>
    </>
  );
});
