import agent from "api/agent";
import { EnsureOk } from "api/utils";
import { CatchError } from "lib/misc/CatchError";
import { PermissionType, Role } from "lib/models";
import { makeAutoObservable, runInAction } from "mobx";

export class RoleStore {
  roles: Role[] = [];

  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  loadAll() {
    return CatchError(async () => {
      const response = await agent.role.getAll();

      EnsureOk(response);

      runInAction(() => {
        this.roles = response.data?.data ?? [];
      });
    }, this.setLoading.bind(this))
  }


  delete(id: string) {
    return CatchError(async () => {
      const response = await agent.role.delete(id);

      EnsureOk(response);
    }, this.setLoading.bind(this))
  }

  update(id: string, data: { name?: string, permissions?: PermissionType[] }) {
    return CatchError(async () => {
      const response = await agent.role.update(id, data);

      EnsureOk(response);
    }, this.setLoading.bind(this))
  }

  create(data: { name: string, permissions: PermissionType[] }) {
    return CatchError(async () => {
      const response = await agent.role.create(data);

      EnsureOk(response);
    }, this.setLoading.bind(this))
  }
}
