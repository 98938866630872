import agent from "api/agent";
import { EnsureOk } from "api/utils";
import { CatchError } from "lib/misc/CatchError";
import { ExpiredActionType, RuleType, UserAccessRule } from "lib/models";
import { makeAutoObservable, runInAction } from "mobx";

export class UserAccessRuleStore {
  rules: UserAccessRule[] = [];

  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  loadAll() {
    return CatchError(async () => {
      const response = await agent.uar.getAll();

      EnsureOk(response);

      runInAction(() => {
        this.rules = response.data?.data ?? [];
      });
    }, this.setLoading.bind(this))
  }

  delete(id: string) {
    return CatchError(async () => {
      const response = await agent.uar.delete(id);

      EnsureOk(response);
    }, this.setLoading.bind(this))
  }

  update(id: string, data: {
    pattern?: string;
    expiredAt?: string;
    expiredAction?: ExpiredActionType;
    type?: RuleType;
    removeExpiredAt?: boolean;
  }) {
    return CatchError(async () => {
      const response = await agent.uar.update(id, data);

      EnsureOk(response);
    }, this.setLoading.bind(this))
  }

  create(data: {
    pattern: string;
    expiredAt?: string;
    expiredAction?: ExpiredActionType;
    type?: RuleType;
  }) {
    return CatchError(async () => {
      const response = await agent.uar.create(data);

      EnsureOk(response);
    }, this.setLoading.bind(this))
  }
}
