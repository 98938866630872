import axios from "axios";
import { AxiosWrapper, BaseAPIMainConfig } from "./utils";

export const misc = {
  getAllSupportedIntegrations: () =>
    AxiosWrapper(
      axios.get<
        {
          key: string;
        }[]
      >("/misc/supported-integrations", BaseAPIMainConfig),
    ),
};
