import { notification } from "antd/lib";

export const CatchError = async (
  callback: () => void | Promise<void>,
  stateSetterCallback?: (value: boolean) => void,
): Promise<boolean> => {
  try {
    if (typeof stateSetterCallback === "function") {
      stateSetterCallback(true);
    }

    await callback();

    return true;
  } catch (error) {
    notification.error({
      type: "error",
      message: `${error}`,
    });
  } finally {
    if (typeof stateSetterCallback === "function") {
      stateSetterCallback(false);
    }
  }

  return false;
};
