import {
  AndroidFilled,
  AppleFilled,
  Html5Filled,
  WindowsFilled,
} from "@ant-design/icons";
import React from "react";
import { PlatformType } from "./index";

type PlatformConstraint = {
  type: PlatformType;
  id: string;
  name: string;
  icon: React.ReactNode;
};

export const PlatformConstraints: {
  [key: number | PlatformType]: PlatformConstraint;
} = {
  [PlatformType.Android]: {
    id: "android",
    name: "Android",
    icon: <AndroidFilled className="text-xl text-green-500" />,
    type: PlatformType.Android,
  },
  [PlatformType.IOS]: {
    id: "ios",
    name: "IOS",
    icon: <AppleFilled className="text-xl text-gray-950" />,
    type: PlatformType.IOS,
  },
  [PlatformType.WebGL]: {
    id: "webgl",
    name: "WebGL",
    icon: <Html5Filled className="text-xl text-orange-400" />,
    type: PlatformType.WebGL,
  },
  [PlatformType.OSX]: {
    id: "osx",
    name: "OSX",
    icon: <AppleFilled className="text-xl text-gray-950" />,
    type: PlatformType.OSX,
  },
  [PlatformType.Windows]: {
    id: "windows",
    name: "Windows",
    icon: <WindowsFilled className="text-xl text-blue-400" />,
    type: PlatformType.Windows,
  },
};
