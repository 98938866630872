import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export const BaseAPIMainConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
};

export const EnsureOk = <T>(response: {
  ok: boolean;
  error?: string;
  data?: T;
}) => {
  if (!response.ok) {
    throw new Error(response.error ?? "Something went wrong");
  }

  return response;
};

export const BodyExtractor = <T>(response: AxiosResponse<T>) => {
  const ok = response.status >= 200 && response.status < 300;

  return {
    ok: ok,
    error: ok ? undefined : (response.data as { message: string })?.message,
    data: ok ? response.data : undefined,
  };
};

export const AxiosWrapper = async <T>(promise: Promise<AxiosResponse<T>>) => {
  try {
    return BodyExtractor(await promise);
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ok: false,
        error: (error.response?.data as { message: string })?.message,
        data: undefined,
      };
    }

    return {
      ok: false,
      error: error?.toString() ?? "Unknown misc occurred.",
      data: undefined,
    };
  }
};
