import { GoogleLogin } from "@react-oauth/google";
import { Button, Divider, Flex, Image, Input, Typography } from "antd";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Navigate } from "react-router-dom";

interface Props { }

export const LoginPage = observer((props: Props) => {
  class Store {
    email: string = "";
    password: string = "";

    constructor() {
      makeAutoObservable(this);
    }

    setEmail(email: string) {
      this.email = email;
    }

    setPassword(email: string) {
      this.password = email;
    }
  }

  const [store] = useState(() => new Store());
  const { authStore } = useGlobalStore();

  if (authStore.getJwt()) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div
        className="h-screen w-screen absolute top-0 left-0 -z-50"
        style={{
          backgroundImage: "url(background.png)",
          backgroundOrigin: "center center",
          backgroundSize: "cover",
        }}
      />

      <Flex className="h-full text-black">
        <div className="flex-[5] justify-center items-center lg:flex hidden">
          <div className="w-[50%] max-w-md">
            <Image src="/logo.svg" alt="logo" preview={false} />
          </div>
        </div>

        <div className="flex-[5] flex justify-center items-center animate-appearance-in">
          <div className="sm:h-full w-full p-10 flex flex-col justify-center">
            <div className="bg-white rounded-3xl p-[10%] py-[20%] flex flex-col justify-center">
              <h1 className="text-2xl sm:text-4xl font-bold">
                Hey, <br />
                Welcome Back!
              </h1>

              <p className="text-sm sm:text-medium text-gray-500 my-2">
                We are very happy to see you back!
              </p>

              <div className="my-1 sm:my-3">
                <Typography.Title level={5}>Email</Typography.Title>
                <Input
                  placeholder="user@theonegamestudio.com"
                  type="email"
                  size="large"
                  value={store.email}
                  onChange={(e) => store.setEmail(e.target.value)}
                />
              </div>

              <div className="my-1 sm:my-3">
                <Typography.Title level={5}>Password</Typography.Title>
                <Input
                  placeholder="••••••••"
                  type="password"
                  size="large"
                  value={store.password}
                  onChange={(e) => store.setPassword(e.target.value)}
                />
              </div>

              <div className="my-1 sm:my-3">
                <Button
                  size="large"
                  type="primary"
                  className="w-full"
                  onClick={() =>
                    authStore.loginCredentials({
                      email: store.email,
                      password: store.password,
                    })
                  }
                  loading={authStore.isLoading}
                >
                  Login
                </Button>
              </div>

              <Divider orientation="center">
                <p className="text-xs px-2 text-gray-500">OR</p>
              </Divider>

              <div className="my-1 sm:my-3 flex justify-center items-center">
                <GoogleLogin
                  onSuccess={(e) =>
                    authStore.loginGoogle({
                      jwt: e.credential,
                    })
                  }
                  onError={console.log}
                />
              </div>
            </div>
          </div>
        </div>
      </Flex>
    </>
  );
});
