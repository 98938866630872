import { Tabs } from "antd";
import { NavbarWrapper } from "app/components/GlobalNavbar";
import router from "lib/router";
import { RouteGuard } from "lib/router/RouteGuard";
import { useBreadcrumb } from "lib/storage/GlobalStore";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { AccountSettingsSection } from "./components/AccountSettingsSection";
import { AdminSettingsSection } from "./components/AdminSettingsSection";
import { GeneralSettingsSection } from "./components/GeneralSettingsSection";

interface Props { }

export const SettingsPage = observer((props: Props) => {
  const { tab = "" } = useParams();

  useBreadcrumb("Settings", [
    {
      title: "Settings",
      path: "/settings",
    },
  ]);

  return (
    <>
      <RouteGuard>
        <NavbarWrapper selection="settings">
          <Tabs
            defaultActiveKey={tab}
            onChange={key => router.navigate(`/settings/${key}`)}
            size="small"
            items={[
              {
                key: "",
                label: "General",
                children: <GeneralSettingsSection />,
              },
              {
                key: "account",
                label: "Account",
                children: <AccountSettingsSection />,
              },
              {
                key: "admin",
                label: "Administration",
                children: <AdminSettingsSection />,
              },
            ]}
          ></Tabs>
        </NavbarWrapper>
      </RouteGuard>
    </>
  );
});
