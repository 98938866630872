import React from 'react';
import { Select } from 'antd';

interface Props {
    onChange: (value: string) => void;
}

export const GameSelect: React.FC<Props> = ({ onChange }) => {
    return (
        <Select style={{ width: 200, marginRight: 16 }} placeholder="Select Game" onChange={onChange}>
            <Select.Option value="game1">Game 1</Select.Option>
            <Select.Option value="game2">Game 2</Select.Option>
        </Select>
    );
};
