import { Button, Divider, Flex, Image, Input, Select, Typography } from "antd";
import { PlatformConstraints } from "lib/models/constraints";
import { useGlobalStore } from "lib/storage/GlobalStore";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import IntegrationSection from "./IntegrationSection";

interface Props { }

export const DetailsApplicationSection = observer((props: Props) => {
  const { appStore } = useGlobalStore();
  const { application } = appStore;

  return (
    <div className="space-y-6">
      {application && (
        <div className="space-y-6">
          <div className="rounded-xl border border-dashed shadow p-6">
            <Flex className="space-x-2" align="center">
              <div>{PlatformConstraints[application.platform].icon}</div>

              <Typography.Text className="text-xl font-semibold">
                App Details
              </Typography.Text>
            </Flex>

            <Divider />

            <Typography.Title level={4} className="pb-2">Information</Typography.Title>

            <AndroidAppInformationSection />

            <Divider />

            <Typography.Title level={4} className="pb-2">Integration</Typography.Title>

            <IntegrationSection />
          </div>
        </div>
      )}
    </div>
  );
});

const AndroidAppInformationSection = observer((props: Props) => {
  class Store {
    properties: { [key: string]: string } = {};

    constructor() {
      makeAutoObservable(this);
    }

    setProperties(value: { [key: string]: string }) {
      this.properties = value;
    }

    updateProperties(callback: (props: { [key: string]: string }) => void) {
      callback(this.properties);
    }
  }

  const [store] = useState(() => new Store());
  const { appStore, ksStore } = useGlobalStore();

  useEffect(() => {
    if (appStore.application) {
      store.setProperties(appStore.application.properties);
    }

    ksStore.loadAll().finally();
  }, [ksStore, appStore.application, store]);

  const refresh = useCallback(() => {
    if (appStore.application) appStore.load(appStore.application.id).finally();
  }, [appStore]);

  return (
    <>
      <Flex>
        <div className="flex-1 px-6 space-y-4">
          <Flex className="space-x-2" align="center">
            <Typography.Text className="flex-none">
              Package Name:
            </Typography.Text>

            <Input
              placeholder="com.example.id"
              value={store.properties.packageName}
              onChange={(e) =>
                store.updateProperties((p) => (p.packageName = e.target.value))
              }
            />
          </Flex>

          <Flex className="space-x-2" align="center">
            <Typography.Text className="flex-none">Version:</Typography.Text>

            <Input
              addonBefore="Name"
              placeholder="1.0.0"
              value={store.properties.versionName}
              onChange={(e) =>
                store.updateProperties((p) => (p.versionName = e.target.value))
              }
            />

            <Input
              addonBefore="Code"
              placeholder="10"
              value={store.properties.versionCode}
              onChange={(e) =>
                store.updateProperties((p) => (p.versionCode = e.target.value))
              }
            />
          </Flex>

          <Flex className="space-x-2" align="center">
            <Typography.Text className="flex-none">SDK:</Typography.Text>

            <Input
              addonBefore="Min"
              placeholder="23"
              value={store.properties.minSdkVersion}
              onChange={(e) =>
                store.updateProperties(
                  (p) => (p.minSdkVersion = e.target.value),
                )
              }
            />

            <Input
              addonBefore="Target"
              placeholder="28"
              value={store.properties.targetSdkVersion}
              onChange={(e) =>
                store.updateProperties(
                  (p) => (p.targetSdkVersion = e.target.value),
                )
              }
            />
          </Flex>

          <Flex className="space-x-2" align="center">
            <Typography.Text className="flex-none">Keystore:</Typography.Text>

            <Select
              className="flex-1"
              options={ksStore.keystores.map((value) => ({
                label: value.name,
                value: value.id,
              }))}
              value={store.properties.keystoreId}
              onSelect={(id) =>
                store.updateProperties((p) => (p.keystoreId = id))
              }
            />
          </Flex>

          <Flex className="space-x-2" align="center" justify="flex-end">
            <Button
              type="default"
              onClick={refresh}
              disabled={appStore.isLoading}
            >
              Reset
            </Button>

            <Button
              type="primary"
              onClick={() =>
                appStore.application &&
                appStore
                  .update(appStore.application.id, {
                    properties: store.properties,
                  })
                  .then(refresh)
              }
              disabled={appStore.isLoading}
            >
              Update
            </Button>
          </Flex>
        </div>

        <div className="flex-none w-[300px]">
          <Image
            src="https://i.pinimg.com/originals/cf/f5/aa/cff5aac1e087caa046569b8054f5ba3f.png"
            preview={false}
          />
        </div>
      </Flex>
    </>
  );
});
