import axios from "axios";
import { AndroidKeystore, PagingRequest, PagingResponse } from "lib/models";
import { AxiosWrapper, BaseAPIMainConfig } from "./utils";

export const androidKeystore = {
  getAll: (params?: PagingRequest) =>
    AxiosWrapper(
      axios.get<PagingResponse<AndroidKeystore>>("/android-keystore", {
        ...BaseAPIMainConfig,
        params: params
      }),
    ),

  delete: (id: string) =>
    AxiosWrapper(axios.delete(`/android-keystore/${id}`, BaseAPIMainConfig)),

  get: (id: string) =>
    AxiosWrapper(
      axios.get<AndroidKeystore>(`/android-keystore/${id}`, BaseAPIMainConfig),
    ),

  create: (data: {
    name: string;
    keystoreUrl: string;
    keystorePass: string;
    keyAlias: string;
    keyAliasPass: string;
  }) => AxiosWrapper(axios.post("/android-keystore", data, BaseAPIMainConfig)),
};
